export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL";

export const PRODUCT_CREATE_REQUEST = "PRODUCT_CREATE_REQUEST";
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS";
export const PRODUCT_CREATE_FAIL = "PRODUCT_CREATE_FAIL";
export const PRODUCT_CREATE_RESET = "PRODUCT_CREATE_RESET";

export const PRODUCT_UPDATE_REQUEST = "PRODUCT_UPDATE_REQUEST";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAIL = "PRODUCT_UPDATE_FAIL";
export const PRODUCT_UPDATE_RESET = "PRODUCT_UPDATE_RESET";

export const PRODUCT_CREATE_REVIEW_REQUEST = "PRODUCT_CREATE_REVIEW_REQUEST";
export const PRODUCT_CREATE_REVIEW_SUCCESS = "PRODUCT_CREATE_REVIEW_SUCCESS";
export const PRODUCT_CREATE_REVIEW_FAIL = "PRODUCT_CREATE_REVIEW_FAIL";
export const PRODUCT_CREATE_REVIEW_RESET = "PRODUCT_CREATE_REVIEW_RESET";

export const PRODUCT_TOP_REQUEST = "PRODUCT_TOP_REQUEST";
export const PRODUCT_TOP_SUCCESS = "PRODUCT_TOP_SUCCESS";
export const PRODUCT_TOP_FAIL = "PRODUCT_TOP_FAIL";

export const PRODUCT_CATEGORY_REQUEST = "PRODUCT_CATEGORY_REQUEST";
export const PRODUCT_CATEGORY_SUCCESS = "PRODUCT_CATEGORY_SUCCESS";
export const PRODUCT_CATEGORY_FAIL = "PRODUCT_CATEGORY_FAIL";

export const GET_ALL_FEATURED_PRODUCT_REQUEST =
  "GET_ALL_FEATURED_PRODUCT_REQUEST";
export const GET_ALL_FEATURED_PRODUCT_SUCCESS =
  "GET_ALL_FEATURED_PRODUCT_SUCCESS";
export const GET_ALL_FEATURED_PRODUCTY_FAIL = " GET_ALL_FEATURED_PRODUCT_FAIL";

export const BEST_SELLER_REQUEST = "BEST_SELLER_REQUEST";
export const BEST_SELLER_SUCCESS = "BEST_SELLER_SUCCESS";
export const BEST_SELLER_FAIL = "BEST_SELLER_FAIL";

export const BEST_SLIDER_CONTENT_REQUEST = "BEST_SLIDER_CONTENT_REQUEST";
export const BEST_SLIDER_CONTENT_SUCCESS = "BEST_SLIDER_CONTENT_SUCCESS";
export const BEST_SLIDER_CONTENT_FAIL = "BEST_SLIDER_CONTENT_FAIL";

export const SHOP_BY_CATEGORY_DETAIL_REQUEST =
  "SHOP_BY_CATEGORY_DETAIL_REQUEST";
export const SHOP_BY_CATEGORY_DETAIL_SUCCESS =
  "SHOP_BY_CATEGORY_DETAIL_SUCCESS";
export const SHOP_BY_CATEGORY_DETAIL_FAIL = "SHOP_BY_CATEGORY_DETAIL_FAIL";

export const SUB_CATEGORY_DETAIL_REQUEST = "SUB_CATEGORY_DETAIL_REQUEST";
export const SUB_CATEGORY_DETAIL_SUCCESS = "SUB_CATEGORY_DETAIL_SUCCESS";
export const SUB_CATEGORY_DETAIL_FAIL = "SUB_CATEGORY_DETAIL_FAIL";

export const PRODUCT_SEARCH_REQUEST = "PRODUCT_SEARCH_REQUEST";
export const PRODUCT_SEARCH_SUCCESS = "PRODUCT_SEARCH_SUCCESS";
export const PRODUCT_SEARCH_FAIL = "PRODUCT_SEARCH_FAIL";

export const APPLY_COUPON_REQUEST = "APPLY_COUPON_REQUEST";
export const APPLY_COUPON_SUCCESS = "APPLY_COUPON_SUCCESS";
export const APPLY_COUPON_FAIL = "APPLY_COUPON_FAIL";
