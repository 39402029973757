import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import {
  productListReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productReviewCreateReducer,
  productTopRatedReducer,
  productCategoryListReducer,
  listOfFeaturedProductsReducer,
  bestSellerReducer,
  SliderContentReducer,
  productCategoryDetailReducer,
  productSubCategoryiesReducer,
  productSearchReducer,
  applyCouponReducer,
} from "./reducers/productReducers";
import {
  addFavouriteToCartReducer,
  cartItemsReducer,
  cartReducer,
  cartUpdateReducer,
  favouriteToCartListReducer,
  removeFavouriteToCartReducer,
} from "./reducers/cartReducers";
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userRegisterModelReducer,
  guestLoginReducer,
  recoverPasswordReducer,
  resetPasswordReducer,
  newsLetterSubscriptionReducer,
  userProfileImageModelReducer,
  userUploadProfileImageReducer,
  userRemoveProfileImageReducer,
  userProfileInfoReducer,
} from "./reducers/userReducers";
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderDeliverReducer,
  orderListMyReducer,
  orderListReducer,
  orderPdfInvoiceReducer,
  reOrderReducer,
  orderRePostPaymentReducer,
  orderShipmentDetailsReducer,
  placeOrderReducer,
} from "./reducers/orderReducers";
import {
  addressListReducer,
  availableShippingMethodsReducer,
  billingAddressReducer,
  checkoutInfoReducer,
  getProvinceFromCountryReducer,
  paymentMethodsReducer,
  saveAddressesReducer,
  saveShippingMethodsReducer,
  shippingAddressesReducer,
  shippingAddressReducer,
} from "./reducers/checkoutReducers";

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productReviewCreate: productReviewCreateReducer,
  productTopRated: productTopRatedReducer,
  cart: cartReducer,
  updatedCart: cartUpdateReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userGuest: guestLoginReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderDeliver: orderDeliverReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,
  prepareRegistrationModel: userRegisterModelReducer,
  productCategoryList: productCategoryListReducer,
  featuredProducts: listOfFeaturedProductsReducer,
  bestSellers: bestSellerReducer,
  getSliderContent: SliderContentReducer,
  listCartItums: cartItemsReducer,
  getshippingAddress: shippingAddressesReducer,
  saveNewShippingAddress: saveAddressesReducer,
  getCheckOutInformation: checkoutInfoReducer,
  getSavePaymentMethods: paymentMethodsReducer,
  orderPlcacedInformation: placeOrderReducer,
  saveBillingAddress: billingAddressReducer,
  saveShippingAddress: shippingAddressReducer,
  getAddressLists: addressListReducer,
  recoverPassword: recoverPasswordReducer,
  resetPassword: resetPasswordReducer,
  productCategoryDetail: productCategoryDetailReducer,
  getSubCategoriesList: productSubCategoryiesReducer,
  searchProductsStore: productSearchReducer,
  savePaymentMehods: saveShippingMethodsReducer,
  newSettlerSubscriptionStore: newsLetterSubscriptionReducer,
  addFavouriteItemToCart: addFavouriteToCartReducer,
  removeFavouriteItemToCart: removeFavouriteToCartReducer,
  listOfFavouriteItemToCart: favouriteToCartListReducer,
  userProfileImageModel: userProfileImageModelReducer,
  userUploadProfileImage: userUploadProfileImageReducer,
  userRemoveProfileImage: userRemoveProfileImageReducer,
  userProfileInfoStore: userProfileInfoReducer,
  applyCouponStore: applyCouponReducer,
  orderPdfInvoiceStore: orderPdfInvoiceReducer,
  reOrderStore: reOrderReducer,
  orderRePostPaymentStore: orderRePostPaymentReducer,
  orderShipmentDetailsStore: orderShipmentDetailsReducer,
  availableShippingMethodsStore: availableShippingMethodsReducer,
  getProvinceFromCountryStore: getProvinceFromCountryReducer,
});

const initialState = {
  userLogin: {
    userLoginInfo: localStorage.getItem("userLoginInfo")
      ? JSON.parse(localStorage.getItem("userLoginInfo"))
      : null,
  },

  userGuest: {
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null,
  },
  // userCart: {
  //   cartItems: localStorage.getItem("cartItems")
  //     ? JSON.parse(localStorage.getItem("cartItems"))
  //     : [],
  // },
  saveNewShippingAddress: {
    shippingAddress: localStorage.getItem("shippingAddress")
      ? JSON.parse(localStorage.getItem("shippingAddress"))
      : {},
  },
};

const middleware = [thunk];
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(...middleware))
);

export default store;
