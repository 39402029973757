import React from "react";
import homeScreenJSON from "../DynamicData/homeScreen.json";
import mobileNavbarJSON from "../DynamicData/mobileNavbar.json";
import UpperNavbarWidget from "../HomePageWidgets/NavbarWidgets/UpperNavbarWidget";
import LowerNavbarWidget from "../HomePageWidgets/NavbarWidgets/LowerNavbarWidget";
import MiddleNavbarWidget from "../HomePageWidgets/NavbarWidgets/MiddleNavbarWidget";
import MobileUpperNavbarWidget from "../HomePageWidgets/NavbarWidgets/MobileUpperNavbarWidget";
import MobileMiddleNavbarWidget from "../HomePageWidgets/NavbarWidgets/MobileMiddleNavbarWidget";
import MobileLowerNavbarWidget from "../HomePageWidgets/NavbarWidgets/MobileLowerNavbarWidget";

const Header = () => {
  return (
    <>
      <header className="header mobile-view">
        {mobileNavbarJSON
          .filter((widget) => widget.Visibility)
          .map((widget) => {
            const { WidgetType, AdditionalProperties } = widget;

            switch (WidgetType) {
              case "MobileUpperNavbarWidget":
                return (
                  <div className="top-bar" key={WidgetType}>
                    <MobileUpperNavbarWidget
                      AdditionalProperties={AdditionalProperties}
                    />
                  </div>
                );
              default:
                return null;
            }
          })}
        <nav className="navbar navbar-expand-lg navbar-light menu-bar">
          <div className="container">
            {mobileNavbarJSON
              .filter((widget) => widget.Visibility)
              .map((widget) => {
                const { WidgetType, AdditionalProperties } = widget;

                switch (WidgetType) {
                  case "MobileMiddleNavbarWidget":
                    return (
                      <>
                        <MobileMiddleNavbarWidget
                          AdditionalProperties={AdditionalProperties}
                        />
                      </>
                    );
                  case "MobileLowerNavbarWidget":
                    return (
                      <>
                        <MobileLowerNavbarWidget
                          key={WidgetType}
                          AdditionalProperties={AdditionalProperties}
                        />
                      </>
                    );
                  default:
                    return null;
                }
              })}
          </div>
        </nav>
      </header>

      <header className="header desktop">
        {homeScreenJSON
          .filter((widget) => widget.Visibility)
          .map((widget) => {
            const { WidgetType, AdditionalProperties } = widget;

            switch (WidgetType) {
              case "UpperNavbarWidget":
                return (
                  <div className="top-bar" key={WidgetType}>
                    <UpperNavbarWidget
                      AdditionalProperties={AdditionalProperties}
                    />
                  </div>
                );
              case "MiddleNavbarWidget":
                return (
                  <div className="second-top-bar mt-4" key={WidgetType}>
                    <div className="container container-des">
                      <MiddleNavbarWidget
                        AdditionalProperties={AdditionalProperties}
                      />
                    </div>
                  </div>
                );
              case "LowerNavbarWidget":
                return (
                  <nav
                    className="navbar navbar-expand-lg navbar-light menu-bar desktop justify-content-center"
                    key={WidgetType}
                  >
                    <LowerNavbarWidget
                      AdditionalProperties={AdditionalProperties}
                    />
                  </nav>
                );

              default:
                return null;
            }
          })}
      </header>
    </>
  );
};

export default Header;
