import React from "react";
import { Link } from "react-router-dom";
import phoneImage from "../../CustomStyle/atcommerce-theme/images/f-phone.png";
import whtsaapImage from "../../CustomStyle/atcommerce-theme/images/f-whatapp.png";

function openWhatsApp(phoneNumber) {
  window.open(`https://wa.me/${phoneNumber}`, "_blank");
}

const NeedHelpWidget = ({ AdditionalProperties }) => {
  const { WidgetContent, WidgetHeading } = AdditionalProperties;
  const phoneNumber = "+923001231234";
  const needHelpWidget = (
    <div className="text-left f-links">
      <h2>{WidgetHeading}</h2>
      <ul className="listing mt-5 contact-footer">
        {WidgetContent.filter((item) => item.Visibility).map((item) => {
          const { WidgetTitle, WidgetDescription, ParticularLink } = item;

          switch (WidgetTitle) {
            case "Phone":
              return (
                <li key={WidgetTitle}>
                  <a href="tel:+923001231234" target="_blank" className="phone">
                    <img src={phoneImage} alt="Phone Image" /> +923001231234
                  </a>
                </li>
              );

            case "Whtsaap":
              return (
                <li key={WidgetTitle}>
                  <a href="#" onClick={() => openWhatsApp(phoneNumber)}>
                    <img src={whtsaapImage} alt="Image of WhatsApp" />{" "}
                    {phoneNumber}
                  </a>
                </li>
              );
            case "Email":
              return (
                <li key={WidgetTitle}>
                  <form action="#" className="in-gro-footer">
                    <div className="input-group">
                      <input
                        className="btn-lg"
                        name="email"
                        id="email"
                        type="email"
                        placeholder="Email"
                        required
                      />
                      <button
                        className="btn-info btn-lg footer-btn-new"
                        type="submit"
                      >
                        {WidgetDescription}
                      </button>
                    </div>
                  </form>
                </li>
              );

            default:
              return null;
          }
        })}
      </ul>
    </div>
  );

  return <div>{needHelpWidget}</div>;
};

export default NeedHelpWidget;
