import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listProductCategories } from "../../actions/productActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import facebookImage from "../../CustomStyle/atcommerce-theme/images/facebook.png";
import instagramImage from "../../CustomStyle/atcommerce-theme/images/instagram.png";
import twitterImage from "../../CustomStyle/atcommerce-theme/images/twitter.png";
import youtubeImage from "../../CustomStyle/atcommerce-theme/images/youtube.png";
import snapchatImage from "../../CustomStyle/atcommerce-theme/images/snapchat.png";
import logomobilenew from "../../CustomStyle/atcommerce-theme/images/atcommerce-logo-mobilenew.png";
import mobilebag from "../../CustomStyle/atcommerce-theme/images/bag.webp";

// ./images/atcommerce-logo-mobilenew.png
// ./images/bag.webp

import { Link } from "react-router-dom";

const MobileLowerNavbarWidget = ({ AdditionalProperties }) => {
  const { WidgetTitle } = AdditionalProperties;
  const dispatch = useDispatch();
  const productCategoryList = useSelector((state) => state.productCategoryList);
  const {
    loading: loadingCategories,
    error: errorCategories,
    categories,
  } = productCategoryList;
  // console.log(categories?.categories_model_dto);

  useEffect(() => {
    dispatch(listProductCategories());
  }, [dispatch]);
  return (
    <div
      className="offcanvas offcanvas-start bg-secondary"
      id="navbarOffcanvas"
      tabIndex="-1"
      aria-labelledby="offcanvasNavbarLabel"
    >
      <div className="offcanvas-header">
        <div className="offcanvas-title" id="offcanvasExampleLabel">
          <img src={logomobilenew} alt="Main mobile Logo" />
        </div>
        <div className="top cart-button">
          <a href="#" className="up-button bag">
            <img src={mobilebag} alt="Main mobile Bag Logo" />
          </a>
        </div>
      </div>
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
      <ul className="navbar-nav justify-content-center">
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="#">
            Home
          </a>
        </li>
        {loadingCategories ? (
          <Loader></Loader>
        ) : errorCategories ? (
          <Message variant="danger">{errorCategories}</Message>
        ) : (
          categories.categories_model_dto.map((category) => (
            <li key={category.name} className="nav-item">
              <Link
                to={`/category/${category.id}?name=${category.name}`}
                className="nav-link"
              >
                {category.name}
              </Link>
            </li>
          ))
        )}
      </ul>
      <div className="mobiles-social-link mt-4 text-center">
        <a href="#">
          <img src={facebookImage} alt=" image of facebook" />
        </a>
        <a href="#">
          <img src={instagramImage} alt=" image of instagram" />
        </a>
        <a href="#">
          <img src={twitterImage} alt=" image of twitter" />
        </a>
        <a href="#">
          <img src={youtubeImage} alt=" image of youtube" />
        </a>
        <a href="#">
          <img src={snapchatImage} alt=" image of snapchat" />
        </a>
      </div>
    </div>
  );
};

export default MobileLowerNavbarWidget;
