import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

// import { logout } from "../../actions/userActions";
import {
  favouriteToCartlistAction,
  getCartItems,
  updateCart,
} from "../../actions/cartActions";
import { useEffect } from "react";
import searchButtonImage from "../../CustomStyle/atcommerce-theme/images/Union.webp";
import mainLogoImage from "../../CustomStyle/atcommerce-theme/images/atcommerce-logo.png";
import vanImage from "../../CustomStyle/atcommerce-theme/images/ven.webp";
import userImage from "../../CustomStyle/atcommerce-theme/images/user.webp";
import heartImage from "../../CustomStyle/atcommerce-theme/images/heart.webp";
import bagImage from "../../CustomStyle/atcommerce-theme/images/bag.webp";
import CartImage from "../../CustomStyle/atcommerce-theme/images/product-image1.png";
import history from "../../Icons/history.svg";
import heartIcon from "../../Icons/heart.svg";
import logoutIcon from "../../Icons/logout.svg";
import setting from "../../Icons/settings.svg";
import {
  logout,
  userProfileImageModelAction,
  userProfileInfoDetailAction,
  userUploadProfileImageAction,
} from "../../actions/userActions";
import { productSearchAction } from "../../actions/productActions";
import { saveShippingMethodsAction } from "../../actions/checkoutActions";

const MiddleNavbarWidget = ({ AdditionalProperties }) => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");

  const { WidgetContent } = AdditionalProperties;
  const userInfo = useSelector((state) => state.userGuest.userInfo);

  const userLogin = useSelector((state) => state.userLogin);
  const { userLoginInfo } = userLogin;
  const user_guids = userLoginInfo
    ? userLoginInfo?.user_guid
    : userInfo?.user_guid;

  const listCartItums = useSelector((state) => state.listCartItums);
  const {
    cartItems,
    // loading: cartItemLoading,
    error: errorItemLoading,
  } = listCartItums;

  // console.log(cartItems);
  const Items = [
    {
      id: 2758,
      quantity: 1,
      sub_total: "2,000.00 (Rs.)",
      unit_price: "2,000.00 (Rs.)",
    },
    {
      id: 1212,
      quantity: 4,
      sub_total: "2,100.00 (Rs.)",
      unit_price: "2,100.00 (Rs.)",
    },
    {
      id: 32,
      quantity: 2,
      sub_total: "2,200.00 (Rs.)",
      unit_price: "2,200.00 (Rs.)",
    },
  ];
  const userProfileImageModel = useSelector(
    (state) => state.userProfileImageModel
  );
  const {
    userProfileModel,
    loading: userProfileModelLoading,
    error: userProfileModelError,
  } = userProfileImageModel;

  const userUploadProfileImage = useSelector(
    (state) => state.userUploadProfileImage
  );

  const {
    UploadProfileImage,
    loading: UploadProfileImageLoading,
    error: UploadProfileImageError,
  } = userProfileImageModel;

  const userRemoveProfileImage = useSelector(
    (state) => state.userRemoveProfileImage
  );

  const {
    userRemoveProfile,
    loading: userRemoveProfileImageLoading,
    error: userRemoveProfileImageError,
  } = userProfileImageModel;

  const userProfileInfoStore = useSelector(
    (state) => state.userProfileInfoStore
  );

  const {
    userProfileInfo,
    loading: userProfileInfoLoading,
    error: userProfileInfoError,
  } = userProfileInfoStore;
  // console.log(userProfileInfo);

  const listOfFavouriteItemToCart = useSelector(
    (state) => state.listOfFavouriteItemToCart
  );
  const {
    loading: loadingFevouriteProductList,
    error: errorFevouriteProductList,
    fevouriteProductList,
  } = listOfFavouriteItemToCart;
  // console.log(fevouriteProductList);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [image, setImage] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const imageData = new Uint8Array(event.target.result);
        const newImageData = imageData.toString();
        const sizeInKB = Math.round(file.size / 1024);
        if (sizeInKB > 20) {
          const img = new Image();
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const MAX_WIDTH = 200;
            const MAX_HEIGHT = 200;
            let width = img.width;
            let height = img.height;
            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);

            const reducedImageData = canvas.toDataURL(file.type, 0.5);
            // console.log(reducedImageData);

            dispatch(userUploadProfileImageAction(reducedImageData));
          };
        } else {
          dispatch(userUploadProfileImageAction(newImageData));
        }
      };
      reader.readAsArrayBuffer(file);
      setImage(URL.createObjectURL(file));
    }
  };

  const handleImageRemove = () => {
    setImage(null);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (userLoginInfo) {
      dispatch(getCartItems());
      dispatch(favouriteToCartlistAction(user_guids));
      dispatch(userProfileImageModelAction());
      dispatch(userProfileInfoDetailAction());
    }
  }, [dispatch, userLoginInfo]);

  function logOutHandler() {
    dispatch(logout());
  }
  const removeFromCartHandler = (id) => {
    const updatedCartItems = cartItems.map((item) =>
      item.id === id ? { ...item, quantity: 0 } : item
    );
    dispatch(updateCart(updatedCartItems, id)).then(() =>
      dispatch(getCartItems())
    );
    // console.log("button clicked");
  };

  const incrementQty = (id) => {
    const updatedCartItems = cartItems.map((item) =>
      item.id === id ? { ...item, quantity: item.quantity + 1 } : item
    );
    dispatch(updateCart(updatedCartItems, id)).then(() =>
      dispatch(getCartItems())
    );
  };

  const decrementQty = (id) => {
    const updatedCartItems = cartItems.map((item) =>
      item.id === id ? { ...item, quantity: item.quantity - 1 } : item
    );
    dispatch(updateCart(updatedCartItems, id)).then(() =>
      dispatch(getCartItems())
    );
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (keyword.trim()) {
      navigate(`/search/${keyword}`);
    } else {
      navigate("/");
    }
  };
  const checkoutHandler = () => {
    if (userLoginInfo) {
      navigate("/shipping");
    } else {
      navigate("/signin");
    }
  };

  const middleNavbarWidget = (
    <>
      <div className="row">
        <div className="col-md-4">
          {WidgetContent?.filter((item) => item.Visibility).map((item) => {
            if (item.WidgetTitle === "search") {
              return (
                <div className="form-outline" key={item.WidgetTitle}>
                  <form onSubmit={submitHandler}>
                    <input
                      type="text"
                      name="q"
                      onChange={(e) => setKeyword(e.target.value)}
                      className="form-control"
                      placeholder={item.PlaceHolder}
                    />
                    <button type="submit">
                      <img src={searchButtonImage} alt="Search Button Image" />
                    </button>
                  </form>
                </div>
              );
            }
            return null;
          })}
        </div>
        <div className="col-md-4">
          {WidgetContent?.filter((item) => item.Visibility).map((item) => {
            if (item.WidgetTitle === "MainLogo") {
              return (
                <div className="text-center" key={item.WidgetTitle}>
                  <Link to="/" className="logo">
                    <img src={mainLogoImage} alt="mainLogo Image" />
                  </Link>
                </div>
              );
            }
            return null;
          })}
        </div>
        <div className="col-md-4">
          <div className="top cart-button text-end d-flex justify-content-end">
            {WidgetContent?.filter((item) => item.Visibility).map((item) => {
              switch (item.WidgetTitle) {
                case "Van":
                  return (
                    <Link
                      to="/shippingScreen"
                      className="up-button p-2"
                      key={item.WidgetTitle}
                    >
                      <img src={vanImage} alt="Van Image" />
                    </Link>
                  );

                case "User":
                  return (
                    <>
                      <Dropdown align={{ lg: "end" }}>
                        <Dropdown.Toggle
                          autoClose="outside"
                          variant="test-header"
                          id="dropdown-basic"
                          className="p-2"
                        >
                          <img src={userImage} alt="User Image" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-menu-new p-0">
                          <div className="d-flex justify-content-between top-part py-2 px-3">
                            <div className="top-name">My Profile</div>
                            <button className="top-part">
                              Close{" "}
                              <Dropdown.Toggle
                                autoClose="outside"
                                variant="test-header"
                                id="dropdown-basic"
                                className="rounded-circle text-white bg-black px-2"
                              >
                                x
                              </Dropdown.Toggle>
                            </button>
                          </div>
                          {userLoginInfo ? (
                            <>
                              <div className="profile-section d-flex pt-4 rounded-circle">
                                <Dropdown.Item
                                  href="#/action-1"
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                  }}
                                >
                                  <div
                                    style={{
                                      height: "66px",
                                      width: "66px",
                                    }}
                                    className="profile-img"
                                  >
                                    {image ? (
                                      <>
                                        <img
                                          className="rounded-circle"
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            border: "1px solid black",
                                          }}
                                          src={image}
                                          alt="Uploaded"
                                        />
                                        {/* <button onClick={handleImageRemove}>
                                          X
                                        </button> */}
                                        <div className="change-img-btn">
                                          <div
                                            style={{
                                              height: "100%",
                                              width: "100%",
                                              position: "relative",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <img
                                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTw8WlYa62x_T7QP0uy7Y-FGmiCKm-akLEx7Q&usqp=CAU"
                                              alt=""
                                              style={{
                                                height: "100%",
                                                width: "100%",

                                                cursor: "pointer",
                                              }}
                                            />
                                          </div>
                                          <input
                                            type="file"
                                            name=""
                                            id=""
                                            className="change-imgs-text-input"
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="upload-wrapper">
                                          <img
                                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTw8WlYa62x_T7QP0uy7Y-FGmiCKm-akLEx7Q&usqp=CAU"
                                            alt=""
                                            style={{
                                              height: "100%",
                                              width: "100%",
                                              cursor: "pointer",
                                            }}
                                          />
                                          {/* <img
                                            className="small-image"
                                            src={userImage}
                                            alt="User Image"
                                          />
                                          <div
                                            className="camera-icon"
                                            onClick={() => {}}
                                          >
                                            <i className="fa fa-camera"></i>
                                          </div> */}
                                          <input
                                            className="change-imgs-text-input"
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageUpload}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </Dropdown.Item>
                                <div className="d-flex flex-column">
                                  <Dropdown.Item>
                                    <span className="fs-24">
                                      {userProfileInfo?.username}
                                    </span>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <span>{userProfileInfo?.email}</span>
                                  </Dropdown.Item>
                                </div>
                              </div>

                              <Dropdown.Item className="py-2">
                                <span>
                                  <Link
                                    className="login-text"
                                    to="/favouriteProducts"
                                  >
                                    <img alt="" src={heartIcon} />
                                    <span className="ps-2">Favourite</span>
                                    <i className="float-end">{">"}</i>
                                  </Link>
                                </span>
                              </Dropdown.Item>
                              <Dropdown.Item className="py-2">
                                <span>
                                  <Link className="login-text" to="/orderMine">
                                    <img src={history} alt="" />
                                    <span className="ps-2">My Order</span>
                                    <i className="float-end">{">"}</i>
                                  </Link>
                                </span>
                              </Dropdown.Item>
                              <Dropdown.Item className="py-2">
                                <span>
                                  <Link
                                    className="login-text"
                                    to="/resetPassword"
                                  >
                                    <img src={setting} alt="" />
                                    <span className="ps-2">
                                      Change Password
                                    </span>
                                    <i className="float-end">{">"}</i>
                                  </Link>
                                </span>
                              </Dropdown.Item>
                              <Dropdown.Item className="border border-bottom-0 border-left-0 border-right-0 py-3">
                                <span>
                                  <Link
                                    className="login-text"
                                    onClick={logOutHandler}
                                  >
                                    <img src={logoutIcon} alt="" />
                                    <span className="ps-2">Log Out</span>{" "}
                                  </Link>
                                </span>
                              </Dropdown.Item>
                            </>
                          ) : (
                            <>
                              {" "}
                              <Dropdown.Item className="py-4">
                                <span className="login-text">
                                  Login to your account
                                </span>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <button
                                  onClick={() => navigate("/demo/signin")}
                                  className="btn btn-primary btn-block submit-btn m-0"
                                >
                                  LOGIN
                                </button>
                              </Dropdown.Item>
                              <Dropdown.Item className="py-4 bottom-text">
                                <span className="acc">
                                  Don't have an account?
                                </span>
                                <Link
                                  className="register px-2"
                                  to="/demo/register"
                                >
                                  Register
                                </Link>
                              </Dropdown.Item>
                            </>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  );
                case "Heart":
                  return (
                    <Link
                      to={item.ParticularLink}
                      className="up-button position-button p-2"
                      key={item.WidgetTitle}
                    >
                      <img src={heartImage} alt="Heart Image" />
                      {fevouriteProductList &&
                        fevouriteProductList.items.length > 0 && (
                          <span className="badge">
                            {fevouriteProductList.items.length}
                          </span>
                        )}
                    </Link>
                  );
                case "Cart":
                  return (
                    <div className="shopping-cart-main p-2">
                      <a
                        data-bs-toggle="offcanvas"
                        href="#offcanvasExample"
                        role="button"
                        aria-controls="offcanvasExample"
                        // to={item.ParticularLink}
                        className="up-button position-button p-2"
                        key={item.WidgetTitle}
                      >
                        <img src={bagImage} alt="Bag Image" />
                        {cartItems && cartItems.length > 0 && (
                          <span className="badge">{cartItems.length}</span>
                        )}
                      </a>
                      <div
                        class="offcanvas offcanvas-end shadow pb-2"
                        tabindex="-1"
                        id="offcanvasExample"
                        aria-labelledby="offcanvasExampleLabel"
                        data-bs-backdrop="false"
                      >
                        <div class="offcanvas-header py-1 px-3 border-bottom top-part py-2">
                          <p className="w-100 poppins cart-desc fs-16">
                            You have {cartItems?.length} item in your cart
                          </p>
                          <span className="px-2">Close </span>
                          <button
                            class="offcanvas-close-btn rounded-circle text-white bg-black px-2 py-1"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasExample"
                            aria-controls="offcanvasExample"
                          >
                            X
                          </button>
                        </div>
                        <div class="offcanvas-body p-0">
                          <div className="items px-3">
                            {cartItems &&
                              cartItems?.map((item) => {
                                return (
                                  <div className="item border-bottom d-flex  py-3">
                                    <div>
                                      <img
                                        style={{ height: "110px" }}
                                        src={item?.picture.image_url}
                                        alt={item?.name}
                                      />
                                    </div>
                                    <div className="ps-2  w-100">
                                      <div className="d-flex justify-content-between w-100 ">
                                        <p className="w-100 item-name poppins fs-12 m-0 pe-2">
                                          {item?.product_name}
                                        </p>
                                        <button
                                          className="remove-item-btn poppins fs-12 p-1 px-2"
                                          onClick={() =>
                                            removeFromCartHandler(item.id)
                                          }
                                        >
                                          X
                                        </button>
                                      </div>
                                      <p className="item-amount-price poppins fs-12 m-0 pt-2">
                                        {item.quantity} x {item?.unit_price}
                                      </p>
                                      <div className="mt-2 d-flex justify-content-between align-items-center">
                                        <div className="d-flex">
                                          <button
                                            className="border p-0 fs-12  px-2 bg-white"
                                            onClick={() =>
                                              decrementQty(item.id)
                                            }
                                          >
                                            -
                                          </button>
                                          <button
                                            className="border p-0  px-2 bg-white text-black number-btn"
                                            disabled
                                          >
                                            {item.quantity}
                                          </button>
                                          <button
                                            className="border p-0 fs-12 px-2 bg-white "
                                            onClick={() =>
                                              incrementQty(item.id)
                                            }
                                          >
                                            +
                                          </button>
                                        </div>
                                        <div className="fs-12 poppins">
                                          {item?.sub_total}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          <div className="d-flex justify-content-between align-items-center px-3 pt-2">
                            <p className="poppins ">Subtotal:</p>
                            <p className="poppins ">
                              {" "}
                              {cartItems &&
                                cartItems
                                  ?.reduce((acc, item) => {
                                    const sub_total_num = parseFloat(
                                      item.sub_total.replace(/[^0-9.-]+/g, "")
                                    );
                                    return acc + sub_total_num;
                                  }, 0)
                                  .toLocaleString()}{" "}
                              (Rs.)
                            </p>
                          </div>
                          <div className="p-3">
                            <Link to={item.ParticularLink}>
                              <button className="w-100 mb-2 py-2  view-cart-btn poppins">
                                VIEW CART
                              </button>
                            </Link>

                            <button
                              className="w-100 py-2 checkout-btn poppins"
                              onClick={checkoutHandler}
                            >
                              CHECKOUT
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                default:
                  return null;
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
  return <div>{middleNavbarWidget}</div>;
};

export default MiddleNavbarWidget;
