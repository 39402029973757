import axios from "axios";
import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_TOP_REQUEST,
  PRODUCT_TOP_SUCCESS,
  PRODUCT_TOP_FAIL,
  PRODUCT_CATEGORY_REQUEST,
  PRODUCT_CATEGORY_SUCCESS,
  PRODUCT_CATEGORY_FAIL,
  GET_ALL_FEATURED_PRODUCT_REQUEST,
  GET_ALL_FEATURED_PRODUCT_SUCCESS,
  GET_ALL_FEATURED_PRODUCTY_FAIL,
  BEST_SELLER_REQUEST,
  BEST_SELLER_SUCCESS,
  BEST_SELLER_FAIL,
  BEST_SLIDER_CONTENT_REQUEST,
  BEST_SLIDER_CONTENT_SUCCESS,
  BEST_SLIDER_CONTENT_FAIL,
  SHOP_BY_CATEGORY_DETAIL_REQUEST,
  SHOP_BY_CATEGORY_DETAIL_SUCCESS,
  SHOP_BY_CATEGORY_DETAIL_FAIL,
  SUB_CATEGORY_DETAIL_REQUEST,
  SUB_CATEGORY_DETAIL_SUCCESS,
  SUB_CATEGORY_DETAIL_FAIL,
  PRODUCT_SEARCH_REQUEST,
  PRODUCT_SEARCH_SUCCESS,
  PRODUCT_SEARCH_FAIL,
  APPLY_COUPON_REQUEST,
  APPLY_COUPON_SUCCESS,
  APPLY_COUPON_FAIL,
} from "../constants/productConstants";
import { logout } from "./userActions";

export const listProducts = () => async (dispatch, getState) => {
  // const { token } = JSON.parse(localStorage.getItem("userInfo"));
  const {
    userGuest: { userInfo },
    userLogin: { userLoginInfo },
  } = getState();

  try {
    dispatch({ type: PRODUCT_LIST_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/Product/GetAllHomePageProducts`,
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
    );

    dispatch({
      type: PRODUCT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listProductDetails = (id) => async (dispatch, getState) => {
  const {
    userGuest: { userInfo },
    userLogin: { userLoginInfo },
  } = getState();
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}Product/GetProductDetails/${id}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
    );

    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload: error?.response?.data,
    });
  }
};

export const deleteProduct = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/products/${id}`, config);

    dispatch({
      type: PRODUCT_DELETE_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: PRODUCT_DELETE_FAIL,
      payload: message,
    });
  }
};

export const createProduct = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/products`, {}, config);

    dispatch({
      type: PRODUCT_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: PRODUCT_CREATE_FAIL,
      payload: message,
    });
  }
};

export const updateProduct = (product) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/products/${product._id}`,
      product,
      config
    );

    dispatch({
      type: PRODUCT_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: PRODUCT_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const createProductReview =
  (productId, review) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_CREATE_REVIEW_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.post(`/api/products/${productId}/reviews`, review, config);

      dispatch({
        type: PRODUCT_CREATE_REVIEW_SUCCESS,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: PRODUCT_CREATE_REVIEW_FAIL,
        payload: message,
      });
    }
  };

export const listTopProducts = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_TOP_REQUEST });

    const { data } = await axios.get(`/api/products/top`);

    dispatch({
      type: PRODUCT_TOP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_TOP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listProductCategories = () => async (dispatch) => {
  dispatch({
    type: PRODUCT_CATEGORY_REQUEST,
  });
  let userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}Catalog/GetCategories`,
      {},
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
    );
    dispatch({ type: PRODUCT_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PRODUCT_CATEGORY_FAIL, payload: error?.message });
  }
};

export const ProductCategoriesDetailAcrion =
  (categoryId, filterApi) => async (dispatch) => {
    dispatch({
      type: SHOP_BY_CATEGORY_DETAIL_REQUEST,
    });
    let userInfo = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}Catalog/GetCategoryProducts/${categoryId}`,
        filterApi,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      dispatch({ type: SHOP_BY_CATEGORY_DETAIL_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: SHOP_BY_CATEGORY_DETAIL_FAIL,
        payload: error?.response?.data,
      });
    }
  };

export const productSubCategoriesAction =
  (categoryId) => async (dispatch, getState) => {
    dispatch({
      type: SUB_CATEGORY_DETAIL_REQUEST,
    });
    let userInfo = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}Catalog/GetCatalogSubCategories/${categoryId}`,

        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      dispatch({ type: SUB_CATEGORY_DETAIL_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: SUB_CATEGORY_DETAIL_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

export const listOfFeaturedProducts = () => async (dispatch) => {
  dispatch({
    type: GET_ALL_FEATURED_PRODUCT_REQUEST,
  });
  let userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}Product/GetCategoryFeaturedProducts`,

      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
    );
    dispatch({ type: GET_ALL_FEATURED_PRODUCT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ALL_FEATURED_PRODUCTY_FAIL, payload: error?.message });
  }
};

export const listOfBestSeller = () => async (dispatch) => {
  dispatch({
    type: BEST_SELLER_REQUEST,
  });
  let userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}Catalog/GetBestSeller`,

      {
        headers: {
          Authorization: `Bearer ${userInfo?.token}`,
        },
      }
    );

    dispatch({ type: BEST_SELLER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: BEST_SELLER_FAIL, payload: error.message });
  }
};

export const listOfSliderContent = () => async (dispatch) => {
  dispatch({
    type: BEST_SLIDER_CONTENT_REQUEST,
  });
  let userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}Catalog/GetNivoSlider`,

      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
    );
    dispatch({ type: BEST_SLIDER_CONTENT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BEST_SLIDER_CONTENT_FAIL,
      payload: error?.message,
    });
  }
};

export const productSearchAction =
  (apiBody, page, value) => async (dispatch) => {
    dispatch({
      type: PRODUCT_SEARCH_REQUEST,
    });

    let userInfo = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}Catalog/Search?q&page=${page}`,
        apiBody,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      dispatch({ type: PRODUCT_SEARCH_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: PRODUCT_SEARCH_FAIL, payload: error.message });
    }
  };

export const applyCouponAction = (value) => async (dispatch, getState) => {
  const {
    userGuest: { userInfo },
    userLogin: { userLoginInfo },
  } = getState();

  dispatch({
    type: APPLY_COUPON_REQUEST,
  });

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}ShoppingCart/ApplyDiscountCoupon?discountCouponCode=${value}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${
            userLoginInfo ? userLoginInfo?.token : userInfo?.token
          }`,
        },
      }
    );
    dispatch({ type: APPLY_COUPON_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: APPLY_COUPON_FAIL, payload: error.response.data.message });
  }
};
