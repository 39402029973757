export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";
export const ORDER_CREATE_RESET = "ORDER_CREATE_RESET";
export const REMOVE_ORDER_CONFIRM_INFO = "REMOVE_ORDER_CONFIRM_INFO";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";

export const ORDER_PAY_REQUEST = "ORDER_PAY_REQUEST";
export const ORDER_PAY_SUCCESS = "ORDER_PAY_SUCCESS";
export const ORDER_PAY_FAIL = "ORDER_PAY_FAIL";
export const ORDER_PAY_RESET = "ORDER_PAY_RESET";

export const ORDER_LIST_MY_REQUEST = "ORDER_LIST_MY_REQUEST";
export const ORDER_LIST_MY_SUCCESS = "ORDER_LIST_MY_SUCCESS";
export const ORDER_LIST_MY_FAIL = "ORDER_LIST_MY_FAIL";
export const ORDER_LIST_MY_RESET = "ORDER_LIST_MY_RESET";

export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";

export const ORDER_DELIVER_REQUEST = "ORDER_DELIVER_REQUEST";
export const ORDER_DELIVER_SUCCESS = "ORDER_DELIVER_SUCCESS";
export const ORDER_DELIVER_FAIL = "ORDER_DELIVER_FAIL";
export const ORDER_DELIVER_RESET = "ORDER_DELIVER_RESET";

export const PLACE_ORDER_REQUEST = "PLACE_ORDER_REQUEST";
export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";
export const PLACE_ORDER_FAIL = "PLACE_ORDER_FAIL";

export const ORDER_PDF_INVOICE_REQUEST = "ORDER_PDF_INVOICE_REQUEST";
export const ORDER_PDF_INVOICE_SUCCESS = "ORDER_PDF_INVOICE_SUCCESS";
export const ORDER_PDF_INVOICE_FAIL = "ORDER_PDF_INVOICE_FAIL";

export const RE_ORDER_REQUEST = "RE_ORDER_REQUEST";
export const RE_ORDER_SUCCESS = "RE_ORDER_SUCCESS";
export const RE_ORDER_FAIL = "RE_ORDER_FAIL";

export const ORDER_RE_POSTPAYMENT_REQUEST = "ORDER_RE_POSTPAYMENT_REQUEST";
export const ORDER_RE_POSTPAYMENT_SUCCESS = "ORDER_RE_POSTPAYMENT_SUCCESS";
export const ORDER_RE_POSTPAYMENT_FAIL = "ORDER_RE_POSTPAYMENT_FAIL";

export const ORDER_SHIPMENT_DETAILS_REQUEST = "ORDER_SHIPMENT_DETAILS_REQUEST";
export const ORDER_SHIPMENT_DETAILS_SUCCESS = "ORDER_SHIPMENT_DETAILS_SUCCESS";
export const ORDER_SHIPMENT_DETAILS_FAIL = "ORDER_SHIPMENT_DETAILS_FAIL";
