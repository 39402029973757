import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_RESET,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_UPDATE_RESET,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_PROFILE_RESET,
  USER_REGISTER_MODEL_FAIL,
  USER_REGISTER_MODEL_REQUEST,
  USER_REGISTER_MODEL_SUCCESS,
  GUEST_LOGIN_REQUEST,
  GUEST_LOGIN_SUCCESS,
  GUEST_LOGIN_FAIL,
  USER_PASSWORD_RECOVER_REQUEST,
  USER_PASSWORD_RECOVER_SUCCESS,
  USER_PASSWORD_RECOVER_FAIL,
  USER_PASSWORD_RESET_REQUEST,
  USER_PASSWORD_RESET_SUCCESS,
  USER_PASSWORD_RESET_FAIL,
  NEWSLETTER_SUBSCRIPTION_REQUEST,
  NEWSLETTER_SUBSCRIPTION_SUCCESS,
  NEWSLETTER_SUBSCRIPTION_FAIL,
  USER_PROFILE_IMAGE_REQUEST,
  USER_PROFILE_IMAGE_SUCCESS,
  USER_PROFILE_IMAGE_FAIL,
  USER_UPLOAD_PROFILE_IMAGE_REQUEST,
  USER_UPLOAD_PROFILE_IMAGE_SUCCESS,
  USER_UPLOAD_PROFILE_IMAGE_FAIL,
  USER_REMOVE_PROFILE_IMAGE_REQUEST,
  USER_REMOVE_PROFILE_IMAGE_SUCCESS,
  USER_REMOVE_PROFILE_IMAGE_FAIL,
  USER_COMPLETE_INFO_REQUEST,
  USER_COMPLETE_INFO_SUCCESS,
  USER_COMPLETE_INFO_FAIL,
} from "../constants/userConstants";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userLoginInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userRegisterModelReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_MODEL_REQUEST:
      return { loading: true };
    case USER_REGISTER_MODEL_SUCCESS:
      return { loading: false, prepareModel: action.payload };
    case USER_REGISTER_MODEL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_DETAILS_RESET:
      return { user: {} };
    default:
      return state;
  }
};

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_PROFILE_RESET:
      return {};
    default:
      return state;
  }
};

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true };
    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case USER_LIST_RESET:
      return { users: [] };
    default:
      return state;
  }
};

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userUpdateReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_RESET:
      return {
        user: {},
      };
    default:
      return state;
  }
};
export const guestLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case GUEST_LOGIN_REQUEST:
      return { loading: true };
    case GUEST_LOGIN_SUCCESS:
      return { loading: false, guestInfo: action.payload };
    case GUEST_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const recoverPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_RECOVER_REQUEST:
      return { loading: true };
    case USER_PASSWORD_RECOVER_SUCCESS:
      return { loading: false, recoverPasswordEmail: action.payload };
    case USER_PASSWORD_RECOVER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const resetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_RESET_REQUEST:
      return { loading: true };
    case USER_PASSWORD_RESET_SUCCESS:
      return { loading: false, resetPasswordResponse: action.payload };
    case USER_PASSWORD_RESET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const newsLetterSubscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case NEWSLETTER_SUBSCRIPTION_REQUEST:
      return { loading: true };
    case NEWSLETTER_SUBSCRIPTION_SUCCESS:
      return { loading: false, newsLetterSubscriptionResponse: action.payload };
    case NEWSLETTER_SUBSCRIPTION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userProfileImageModelReducer = (
  state = { userProfileModel: {} },
  action
) => {
  switch (action.type) {
    case USER_PROFILE_IMAGE_REQUEST:
      return { loading: true };
    case USER_PROFILE_IMAGE_SUCCESS:
      return { loading: false, userProfileModel: action.payload };
    case USER_PROFILE_IMAGE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const userUploadProfileImageReducer = (
  state = { UploadProfileImage: {} },
  action
) => {
  switch (action.type) {
    case USER_UPLOAD_PROFILE_IMAGE_REQUEST:
      return { loading: true };
    case USER_UPLOAD_PROFILE_IMAGE_SUCCESS:
      return { loading: false, UploadProfileImage: action.payload };
    case USER_UPLOAD_PROFILE_IMAGE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userRemoveProfileImageReducer = (
  state = { userRemoveProfileImage: {} },
  action
) => {
  switch (action.type) {
    case USER_REMOVE_PROFILE_IMAGE_REQUEST:
      return { loading: true };
    case USER_REMOVE_PROFILE_IMAGE_SUCCESS:
      return { loading: false, userRemoveProfile: action.payload };
    case USER_REMOVE_PROFILE_IMAGE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userProfileInfoReducer = (
  state = { userProfileInfo: {} },
  action
) => {
  switch (action.type) {
    case USER_COMPLETE_INFO_REQUEST:
      return { loading: true };
    case USER_COMPLETE_INFO_SUCCESS:
      return { loading: false, userProfileInfo: action.payload };
    case USER_COMPLETE_INFO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
