import React, { useEffect, lazy, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { guestLogin } from "./actions/userActions";
import BootExample from "./screens/BootExample";
import AllBestSellers from "./screens/AllBestSellers";
import AllFeaturedProducts from "./screens/AllFeaturedProducts";
const HomeScreen = React.lazy(() => import("./screens/HomeScreen"));
const ProductScreen = React.lazy(() => import("./screens/ProductScreen"));
const CartScreen = React.lazy(() => import("./screens/CartScreen"));
const RegisterScreen = React.lazy(() => import("./screens/RegisterScreen"));
const ProfileScreen = React.lazy(() => import("./screens/ProfileScreen"));
const PlaceOrderScreen = React.lazy(() => import("./screens/PlaceOrderScreen"));
const OrderScreen = React.lazy(() => import("./screens/OrderScreen"));
const UserListScreen = React.lazy(() => import("./screens/UserListScreen"));
const UserEditScreen = React.lazy(() => import("./screens/UserEditScreen"));
const ProductListScreen = React.lazy(() =>
  import("./screens/ProductListScreen")
);
const ProductEditScreen = React.lazy(() =>
  import("./screens/ProductEditScreen")
);
const OrderListScreen = React.lazy(() => import("./screens/OrderListScreen"));
const CheckOutScreen = React.lazy(() => import("./screens/CheckOutScreen"));
const MyOrder = React.lazy(() => import("./screens/MyOrder"));
const SignInScreen = React.lazy(() => import("./screens/SignInScreen"));
const ForgotPasswordScreen = React.lazy(() =>
  import("./screens/ForgotPasswordScreen")
);
const ResetPasswordScreen = React.lazy(() =>
  import("./screens/ResetPasswordScreen")
);
const FilterScreen = React.lazy(() => import("./screens/FilterScreen"));
const SaleScreen = React.lazy(() => import("./screens/SaleScreen"));
const Demo = React.lazy(() => import("./screens/Demo"));
const SearchScreen = React.lazy(() => import("./screens/SearchScreen"));
const ShippingScreen = React.lazy(() => import("./screens/ShippingScreen"));
const FavouriteProductsScreen = React.lazy(() =>
  import("./screens/FavouriteProductsScreen")
);
const ViewAllScreen = React.lazy(() => import("./screens/ViewAllScreen"));
const AboutUs = React.lazy(() => import("./screens/AboutUs"));
const MensCollection = React.lazy(() => import("./screens/MensCollection"));
const Catelogue = React.lazy(() => import("./screens/Catelogue"));
const Careers = React.lazy(() => import("./screens/Careers"));
const DeliveryAndOrder = React.lazy(() => import("./screens/DeliveryAndOrder"));
const ReturnAndExchange = React.lazy(() =>
  import("./screens/ReturnAndExchange")
);
const TermsAndCondition = React.lazy(() =>
  import("./screens/TermsAndCondition")
);
const PaymentGuide = React.lazy(() => import("./screens/PaymentGuide"));
const PrivacyPolicy = React.lazy(() => import("./screens/PrivacyPolicy"));
const OrderTracking = React.lazy(() => import("./screens/OrderTracking"));
const FlexiblePayment = React.lazy(() => import("./screens/FlexiblePayment"));
const FreeShipping = React.lazy(() => import("./screens/FreeShipping"));
const CustomerSupport = React.lazy(() => import("./screens/CustomerSupport"));
const OrderTrackingDetail = React.lazy(() =>
  import("./screens/OrderTrackingDetail")
);

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userGuest = useSelector((state) => state.userGuest);
  const { guestInfo } = userGuest;

  const guestFromLocalStorage = localStorage.getItem("userInfo");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!guestFromLocalStorage) {
      dispatch(guestLogin());
    }
  }, [location]);
  return (
    <div className="wrapper">
      {guestFromLocalStorage && (
        <>
          <Header />
          <Suspense fallback={<div>Website is Loading..</div>}>
            <Routes>
              <Route path="/order/:id" element={<OrderScreen />} />
              <Route path="/shippingScreen" element={<ShippingScreen />} />
              <Route path="/shipping" element={<CheckOutScreen />} />
              <Route path="/sale" element={<SaleScreen />} />
              <Route path="/placeorder" element={<PlaceOrderScreen />} />
              <Route path="/signin" element={<SignInScreen />} />
              <Route path="/demo/:name" element={<Demo />} />
              <Route path="/register" element={<RegisterScreen />} />
              <Route
                path="/forgotPassword"
                element={<ForgotPasswordScreen />}
              />
              <Route path="/resetPassword" element={<ResetPasswordScreen />} />
              <Route path="/bestSellers" element={<AllBestSellers />} />
              <Route
                path="/allFeaturedProducts"
                element={<AllFeaturedProducts />}
              />
              <Route path="/profile" element={<ProfileScreen />} />
              <Route path="/product/:id" element={<ProductScreen />} />
              <Route path="/cart" element={<CartScreen />} />
              <Route path="/admin/userlist" element={<UserListScreen />} />
              <Route path="/orderMine" element={<MyOrder />} />
              <Route
                path="/favouriteProducts"
                element={<FavouriteProductsScreen />}
              />
              <Route path="/productsDetail" element={<ViewAllScreen />} />
              <Route path="/admin/user/:id/edit" element={<UserEditScreen />} />
              <Route
                path="/admin/productlist"
                element={<ProductListScreen />}
                exact
              />
              <Route
                path="/admin/productlist/:pageNumber"
                element={<ProductListScreen />}
                exact
              />
              <Route
                path="/admin/product/:id/edit"
                element={<ProductEditScreen />}
              />
              <Route path="/admin/orderlist" element={<OrderListScreen />} />
              <Route path="/category/:id" element={<FilterScreen />} />
              <Route path="/search/:keyword" element={<SearchScreen />} exact />
              <Route path="/page/:pageNumber" element={<HomeScreen />} exact />
              <Route
                path="/search/:keyword/page/:pageNumber"
                component={<HomeScreen />}
                exact
              />
              <Route path="/orderTracking" element={<OrderTracking />} />
              <Route
                path="/orderTrackingDetail/:id"
                element={<OrderTrackingDetail />}
              />
              <Route path="/freeShipping" element={<FreeShipping />} />
              <Route path="/flexiblePayment" element={<FlexiblePayment />} />
              <Route path="/customerSupport" element={<CustomerSupport />} />
              <Route path="/aboutUs" element={<AboutUs />} />
              <Route path="/mens" element={<MensCollection />} />
              <Route path="/catelogue" element={<Catelogue />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/deliveryAndOrder" element={<DeliveryAndOrder />} />
              <Route
                path="/returnAndExchange"
                element={<ReturnAndExchange />}
              />
              <Route
                path="/termsAndCondition"
                element={<TermsAndCondition />}
              />
              <Route path="/paymentGuide" element={<PaymentGuide />} />
              <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/" element={<HomeScreen />} exact />
            </Routes>
          </Suspense>

          <Footer />
        </>
      )}
    </div>
  );
};

export default App;
