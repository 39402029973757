import React from "react";
import { Link } from "react-router-dom";
import InsightImage from "../../CustomStyle/atcommerce-theme/images/insights.webp";

const OrderTrackingWidget = ({ AdditionalProperties }) => {
  const { WidgetContent } = AdditionalProperties;

  const orderTrackingWidget = (
    <div className="text-center box">
      {WidgetContent.map((item) => {
        const { WidgetTitle, WidgetDescription, ParticularLink } = item;
        return (
          <div key={WidgetTitle}>
            <Link to={ParticularLink}>
              <img src={InsightImage} alt="Image of Order Tracking" />
            </Link>
            <h2>{WidgetTitle}</h2>
            <p>{WidgetDescription}</p>
          </div>
        );
      })}
    </div>
  );
  return <div>{orderTrackingWidget}</div>;
};

export default OrderTrackingWidget;
