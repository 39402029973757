import React from "react";

const SmallSizeSvg = () => {
  return (
    <div>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="29" height="29" stroke="#C3C3C3" />
        <path
          d="M15.0939 21.112C14.3579 21.112 13.7072 20.984 13.1419 20.728C12.5872 20.472 12.1499 20.1147 11.8299 19.656C11.5099 19.1973 11.3392 18.6853 11.3179 18.12H12.5019C12.5552 18.664 12.7952 19.144 13.2219 19.56C13.6485 19.9653 14.2725 20.168 15.0939 20.168C15.8512 20.168 16.4485 19.976 16.8859 19.592C17.3339 19.1973 17.5579 18.7013 17.5579 18.104C17.5579 17.624 17.4352 17.24 17.1899 16.952C16.9445 16.6533 16.6405 16.4347 16.2779 16.296C15.9152 16.1467 15.4139 15.9867 14.7739 15.816C14.0272 15.6133 13.4352 15.416 12.9979 15.224C12.5605 15.032 12.1872 14.7333 11.8779 14.328C11.5685 13.9227 11.4139 13.3733 11.4139 12.68C11.4139 12.104 11.5632 11.592 11.8619 11.144C12.1605 10.6853 12.5819 10.328 13.1259 10.072C13.6699 9.816 14.2939 9.688 14.9979 9.688C16.0325 9.688 16.8645 9.944 17.4939 10.456C18.1339 10.9573 18.5019 11.608 18.5979 12.408H17.3819C17.3072 11.9493 17.0565 11.544 16.6299 11.192C16.2032 10.8293 15.6272 10.648 14.9019 10.648C14.2299 10.648 13.6699 10.8293 13.2219 11.192C12.7739 11.544 12.5499 12.0293 12.5499 12.648C12.5499 13.1173 12.6725 13.496 12.9179 13.784C13.1632 14.072 13.4672 14.2907 13.8299 14.44C14.2032 14.5893 14.7045 14.7493 15.3339 14.92C16.0592 15.1227 16.6459 15.3253 17.0939 15.528C17.5419 15.72 17.9205 16.0187 18.2299 16.424C18.5392 16.8293 18.6939 17.3733 18.6939 18.056C18.6939 18.5787 18.5552 19.0747 18.2779 19.544C18.0005 20.0133 17.5899 20.392 17.0459 20.68C16.5019 20.968 15.8512 21.112 15.0939 21.112Z"
          fill="#323232"
        />
      </svg>
    </div>
  );
};

export default SmallSizeSvg;
