import React from "react";

const FooterContentWidget = ({ AdditionalProperties }) => {
  const { WidgetContent } = AdditionalProperties;

  const footerContentWidget = (
    <>
      {WidgetContent?.map((item) => {
        const { WidgetTitle, Visibility } = item;
        return (
          <div className="text-left copyright" key={WidgetTitle}>
            {Visibility && <>{WidgetTitle}</>}
          </div>
        );
      })}
    </>
  );
  return <>{footerContentWidget}</>;
};

export default FooterContentWidget;
