import React from "react";

const PeachColorSvg = () => {
  return (
    <div>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="29"
          height="29"
          rx="14.5"
          fill="#F9F1E1"
          stroke="black"
        />
      </svg>
    </div>
  );
};

export default PeachColorSvg;
