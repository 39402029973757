import {
  AVAILABLE_SHIPPING_METHOD_FAIL,
  AVAILABLE_SHIPPING_METHOD_REQUEST,
  AVAILABLE_SHIPPING_METHOD_SUCCESS,
  GET_ADDRESS_LIST_FAIL,
  GET_ADDRESS_LIST_REQUEST,
  GET_ADDRESS_LIST_SUCCESS,
  GET_CHECKOUT_INFO_FAIL,
  GET_CHECKOUT_INFO_REQUEST,
  GET_CHECKOUT_INFO_SUCCESS,
  GET_PROVINCE_FROM_COUNTRY_FAIL,
  GET_PROVINCE_FROM_COUNTRY_REQUEST,
  GET_PROVINCE_FROM_COUNTRY_SUCCESS,
  GET_SHIPPING_ADDRESS_FAIL,
  GET_SHIPPING_ADDRESS_REQUEST,
  GET_SHIPPING_ADDRESS_SUCCESS,
  PLACE_ORDER_FAIL,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  SAVE_PAYMENT_METHOD_FAIL,
  SAVE_PAYMENT_METHOD_REQUEST,
  SAVE_PAYMENT_METHOD_SUCCESS,
  SAVE_SHIPPING_ADDRESS_FAIL,
  SAVE_SHIPPING_ADDRESS_REQUEST,
  SAVE_SHIPPING_ADDRESS_SUCCESS,
  SAVE_SHIPPING_METHOD_FAIL,
  SAVE_SHIPPING_METHOD_REQUEST,
  SAVE_SHIPPING_METHOD_SUCCESS,
  SELECT_BILLING_ADDRESS_FAIL,
  SELECT_BILLING_ADDRESS_REQUEST,
  SELECT_BILLING_ADDRESS_SUCCESS,
  SELECT_SHIPPING_ADDRESS_FAIL,
  SELECT_SHIPPING_ADDRESS_REQUEST,
  SELECT_SHIPPING_ADDRESS_SUCCESS,
} from "../constants/checkoutConstants";

export const shippingAddressesReducer = (
  state = { shippingAddresses: [] },
  action
) => {
  switch (action.type) {
    case GET_SHIPPING_ADDRESS_REQUEST:
      return { loading: true };
    case GET_SHIPPING_ADDRESS_SUCCESS:
      return { loading: false, shippingAddresses: action.payload };
    case GET_SHIPPING_ADDRESS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const saveAddressesReducer = (
  state = { shippingAddresses: [] },
  action
) => {
  switch (action.type) {
    case SAVE_SHIPPING_ADDRESS_REQUEST:
      return { loading: true };
    case SAVE_SHIPPING_ADDRESS_SUCCESS:
      return { loading: false, shippingAddress: action.payload };
    case SAVE_SHIPPING_ADDRESS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const checkoutInfoReducer = (state = { checkOutInfo: [] }, action) => {
  switch (action.type) {
    case GET_CHECKOUT_INFO_REQUEST:
      return { loading: true };
    case GET_CHECKOUT_INFO_SUCCESS:
      return { loading: false, checkOutInfo: action.payload };
    case GET_CHECKOUT_INFO_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const paymentMethodsReducer = (
  state = { paymentMethod: [] },
  action
) => {
  switch (action.type) {
    case SAVE_PAYMENT_METHOD_REQUEST:
      return { loading: true };
    case SAVE_PAYMENT_METHOD_SUCCESS:
      return { loading: false, paymentMethodInfo: action.payload };
    case SAVE_PAYMENT_METHOD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const billingAddressReducer = (state = { order: [] }, action) => {
  switch (action.type) {
    case SELECT_BILLING_ADDRESS_REQUEST:
      return { loading: true, success: false };
    case SELECT_BILLING_ADDRESS_SUCCESS:
      return { loading: false, success: true, billingAddress: action.payload };
    case SELECT_BILLING_ADDRESS_FAIL:
      return { loading: false, success: false, error: action.payload };

    default:
      return state;
  }
};

export const shippingAddressReducer = (state = { order: [] }, action) => {
  switch (action.type) {
    case SELECT_SHIPPING_ADDRESS_REQUEST:
      return { loading: true };
    case SELECT_SHIPPING_ADDRESS_SUCCESS:
      return { loading: false, shippingAddress: action.payload };
    case SELECT_SHIPPING_ADDRESS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const availableShippingMethodsReducer = (state = {}, action) => {
  switch (action.type) {
    case AVAILABLE_SHIPPING_METHOD_REQUEST:
      return { loading: true };
    case AVAILABLE_SHIPPING_METHOD_SUCCESS:
      return {
        loading: false,
        availableShippingMethodsResponse: action.payload,
      };
    case AVAILABLE_SHIPPING_METHOD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const saveShippingMethodsReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVE_SHIPPING_METHOD_REQUEST:
      return { loading: true };
    case SAVE_SHIPPING_METHOD_SUCCESS:
      return { loading: false, savePaymentMethod: action.payload };
    case SAVE_SHIPPING_METHOD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const addressListReducer = (state = { getAddress: [] }, action) => {
  switch (action.type) {
    case GET_ADDRESS_LIST_REQUEST:
      return { loading: true };
    case GET_ADDRESS_LIST_SUCCESS:
      return { loading: false, getAddress: action.payload };
    case GET_ADDRESS_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
export const getProvinceFromCountryReducer = (
  state = { getProvinceFromCountry: [] },
  action
) => {
  switch (action.type) {
    case GET_PROVINCE_FROM_COUNTRY_REQUEST:
      return { loading: true };
    case GET_PROVINCE_FROM_COUNTRY_SUCCESS:
      return { loading: false, getProvinceFromCountry: action.payload };
    case GET_PROVINCE_FROM_COUNTRY_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
