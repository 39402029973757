import axios from "axios";
import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_MODEL_FAIL,
  USER_REGISTER_MODEL_REQUEST,
  USER_REGISTER_MODEL_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_DETAILS_RESET,
  USER_LIST_FAIL,
  USER_LIST_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_UPDATE_FAIL,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_REQUEST,
  GUEST_LOGIN_REQUEST,
  GUEST_LOGIN_SUCCESS,
  GUEST_LOGIN_FAIL,
  USER_PASSWORD_RECOVER_REQUEST,
  USER_PASSWORD_RECOVER_SUCCESS,
  USER_PASSWORD_RECOVER_FAIL,
  USER_PASSWORD_RESET_REQUEST,
  USER_PASSWORD_RESET_SUCCESS,
  USER_PASSWORD_RESET_FAIL,
  NEWSLETTER_SUBSCRIPTION_SUCCESS,
  NEWSLETTER_SUBSCRIPTION_FAIL,
  NEWSLETTER_SUBSCRIPTION_REQUEST,
  USER_UPLOAD_PROFILE_IMAGE_REQUEST,
  USER_UPLOAD_PROFILE_IMAGE_SUCCESS,
  USER_UPLOAD_PROFILE_IMAGE_FAIL,
  USER_PROFILE_IMAGE_REQUEST,
  USER_PROFILE_IMAGE_SUCCESS,
  USER_PROFILE_IMAGE_FAIL,
  USER_REMOVE_PROFILE_IMAGE_REQUEST,
  USER_REMOVE_PROFILE_IMAGE_SUCCESS,
  USER_REMOVE_PROFILE_IMAGE_FAIL,
  USER_COMPLETE_INFO_REQUEST,
  USER_COMPLETE_INFO_SUCCESS,
  USER_COMPLETE_INFO_FAIL,
} from "../constants/userConstants";
import { ORDER_LIST_MY_RESET } from "../constants/orderConstants";

export const loginUserAction =
  (userName, email, password) => async (dispatch, getState) => {
    const {
      userGuest: { userInfo },
    } = getState();
    try {
      dispatch({
        type: USER_LOGIN_REQUEST,
      });

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo?.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/Authenticate/GetToken`,
        { is_guest: false, userName, email, password },
        config
      );

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      localStorage.setItem("userLoginInfo", JSON.stringify(data));
      document.location.href = "/";
    } catch (error) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
export const guestLogin = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GUEST_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/Authenticate/GetToken`,
      { is_guest: true, username: "", email: "", password: "" },
      config
    );

    dispatch({
      type: GUEST_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: GUEST_LOGIN_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

export const logout = () => (dispatch, getState) => {
  // localStorage.removeItem("userInfo");
  localStorage.removeItem("userLoginInfo");

  localStorage.removeItem("cartItems");
  localStorage.removeItem("shippingAddress");
  localStorage.removeItem("paymentMethod");
  localStorage.removeItem("isFavouriteMap");
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });
  dispatch({ type: ORDER_LIST_MY_RESET });
  dispatch({ type: USER_LIST_RESET });
  document.location.href = "/demo/signin";
};

export const register = (newData) => async (dispatch, getState) => {
  const {
    userGuest: { userInfo },
  } = getState();

  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}Customer/Register`,
      {
        ...newData,
      },
      config
    );

    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    document.location.href = "/demo/signin";
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload: error?.response?.data[0],
    });
  }
};

export const prepareRegister = () => async (dispatch, getState) => {
  const {
    userGuest: { userInfo },
  } = getState();

  try {
    dispatch({
      type: USER_REGISTER_MODEL_REQUEST,
    });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}Customer/Register`,
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
    );

    dispatch({
      type: USER_REGISTER_MODEL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_REGISTER_MODEL_FAIL,
      payload: error.response.message,
    });
  }
};

export const getUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/users/${id}`, config);

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/users/profile`, user, config);

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload: message,
    });
  }
};

export const listUsers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/users`, config);

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_LIST_FAIL,
      payload: message,
    });
  }
};

export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/users/${id}`, config);

    dispatch({ type: USER_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_DELETE_FAIL,
      payload: message,
    });
  }
};

export const updateUser = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/users/${user._id}`, user, config);

    dispatch({ type: USER_UPDATE_SUCCESS });

    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });

    dispatch({ type: USER_DETAILS_RESET });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const recoverPasswordAction = (email) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_PASSWORD_RECOVER_REQUEST,
    });
    let recoverPasswordApiBody = {
      email: email,
      result: "",
      display_captcha: false,
      custom_properties: {},
    };
    let userInfo = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}Customer/PasswordRecoverySend`,
      recoverPasswordApiBody,
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
    );

    dispatch({
      type: USER_PASSWORD_RECOVER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PASSWORD_RECOVER_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const resetPasswordAction =
  (oldPassword, newPassword, confirmPassword) => async (dispatch, getState) => {
    const {
      userLogin: { userLoginInfo },
    } = getState();
    try {
      dispatch({
        type: USER_PASSWORD_RESET_REQUEST,
      });
      let resetPasswordApiBody = {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_new_password: confirmPassword,
        custom_properties: {},
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}Customer/ChangePassword`,
        resetPasswordApiBody,
        {
          headers: {
            Authorization: `Bearer ${userLoginInfo?.token}`,
          },
        }
      );

      dispatch({
        type: USER_PASSWORD_RESET_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_PASSWORD_RESET_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data
            : error.message,
      });
    }
  };

export const newsLetterSubscriptionAction =
  (email) => async (dispatch, getState) => {
    try {
      dispatch({
        type: NEWSLETTER_SUBSCRIPTION_REQUEST,
      });

      let userInfo = localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : null;

      const { data } = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }Newsletter/SubscribeNewsletter?email=${email}&subscribe=${true}`,

        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      dispatch({
        type: NEWSLETTER_SUBSCRIPTION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: NEWSLETTER_SUBSCRIPTION_FAIL,
        payload: error.response.data,
      });
    }
  };

export const userProfileImageModelAction =
  (email) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_PROFILE_IMAGE_REQUEST,
      });

      let userLoginInfo = localStorage.getItem("userLoginInfo")
        ? JSON.parse(localStorage.getItem("userLoginInfo"))
        : null;

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}Customer/Avatar`,

        {
          headers: {
            Authorization: `Bearer ${userLoginInfo.token}`,
          },
        }
      );

      dispatch({
        type: USER_PROFILE_IMAGE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_PROFILE_IMAGE_FAIL,
        payload: error,
      });
    }
  };

export const userUploadProfileImageAction =
  (blobData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_UPLOAD_PROFILE_IMAGE_REQUEST,
      });
      // console.log(blobData);

      let userLoginInfo = localStorage.getItem("userLoginInfo")
        ? JSON.parse(localStorage.getItem("userLoginInfo"))
        : null;

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}Customer/UploadAvatar?fileName=Avatar&contentType=png`,
        blobData,
        {
          headers: {
            Authorization: `Bearer ${userLoginInfo.token}`,
          },
        }
      );

      dispatch({
        type: USER_UPLOAD_PROFILE_IMAGE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_UPLOAD_PROFILE_IMAGE_FAIL,
        payload: error,
      });
    }
  };

export const userRemoveProfileImageAction =
  (email) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_REMOVE_PROFILE_IMAGE_REQUEST,
      });

      let userLoginInfo = localStorage.getItem("userLoginInfo")
        ? JSON.parse(localStorage.getItem("userLoginInfo"))
        : null;

      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}Customer/RemoveAvatar`,

        {
          headers: {
            Authorization: `Bearer ${userLoginInfo.token}`,
          },
        }
      );

      dispatch({
        type: USER_REMOVE_PROFILE_IMAGE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_REMOVE_PROFILE_IMAGE_FAIL,
        payload: error,
      });
    }
  };

export const userProfileInfoDetailAction =
  (email) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_COMPLETE_INFO_REQUEST,
      });

      let userLoginInfo = localStorage.getItem("userLoginInfo")
        ? JSON.parse(localStorage.getItem("userLoginInfo"))
        : null;

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}Customer/Info`,

        {
          headers: {
            Authorization: `Bearer ${userLoginInfo.token}`,
          },
        }
      );

      dispatch({
        type: USER_COMPLETE_INFO_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_COMPLETE_INFO_FAIL,
        payload: error,
      });
    }
  };
