export const CART_ADD_ITEM = "CART_ADD_ITEM";

export const CART_INCREASE_ITEM = "CART_INCREASE_ITEM";
export const CART_DECREASE_ITEM = "CART_DECREASE_ITEM";
export const CART_CLEAR_ITEMS = "CART_RESET";
export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";

export const CART_SAVE_SHIPPING_ADDRESS = "CART_SAVE_SHIPPING_ADDRESS";
export const CART_SAVE_PAYMENT_METHOD = "CART_SAVE_PAYMENT_METHOD";
export const CART_DELIVERY_METHOD = "CART_DELIVERY_METHOD";

export const CART_SHIPPING_ADDRESS_REQUEST = "CART_SHIPPING_ADDRESS_REQUEST";
export const CART_SHIPPING_ADDRESS_SUCCESS = "CART_SHIPPING_ADDRESS_SUCCESS";

export const CART_SHIPPING_ADDRESS_FAIL = "CART_SHIPPING_ADDRESS_FAIL";

export const GET_ALL_CART_DATA = "GET_ALL_CART_DATA";

export const GET_CART_ITEMS_REQUEST = "GET_CART_ITEMS_REQUEST";
export const GET_CART_ITEMS_SUCCESS = "GET_CART_ITEMS_SUCCESS";

export const GET_CART_ITEMS_FAIL = "GET_CART_ITEMS_FAIL";

export const CART_UPDATE_ITEMS_REQUEST = "CART_UPDATE_ITEMS_REQUEST";
export const CART_UPDATE_ITEMS_SUCCESS = "CART_UPDATE_ITEMS_SUCCESS";
export const CART_UPDATE_ITEMS_FAIL = "CART_UPDATE_ITEMS_FAIL";

export const ADD_FAVOURITE_TO_REQUEST = "ADD_FAVOURITE_TO_REQUEST";
export const ADD_FAVOURITE_TO_SUCCESS = "ADD_FAVOURITE_TO_SUCCESS";
export const ADD_FAVOURITE_TO_FAIL = "ADD_FAVOURITE_TO_FAIL";

export const LIST_OF_FAVOURITE_TO_REQUEST = "LIST_OF_FAVOURITE_TO_REQUEST";
export const LIST_OF_FAVOURITE_TO_SUCCESS = "LIST_OF_FAVOURITE_TO_SUCCESS";
export const LIST_OF_FAVOURITE_TO_FAIL = "LIST_OF_FAVOURITE_TO_FAIL";

export const REMOVE_FAVOURITE_TO_REQUEST = "LIST_OF_FAVOURITE_TO_REQUEST";
export const REMOVE_FAVOURITE_TO_SUCCESS = "LIST_OF_FAVOURITE_TO_SUCCESS";
export const REMOVE_FAVOURITE_TO_FAIL = "LIST_OF_FAVOURITE_TO_FAIL";
