import React from "react";

import { Link } from "react-router-dom";

const QuickLinkWidget = ({ AdditionalProperties }) => {
  const { WidgetContent, WidgetHeading } = AdditionalProperties;
  const quickLinksWidget = (
    <div className="text-left f-links">
      <h2>{WidgetHeading}</h2>
      <ul className="listing mt-5">
        {WidgetContent?.map((item) => {
          const { WidgetTitle, ParticularLink, Visibility } = item;
          return (
            <li key={WidgetTitle}>
              {Visibility && <Link to={ParticularLink}>{WidgetTitle}</Link>}
            </li>
          );
        })}
      </ul>
    </div>
  );
  return <div>{quickLinksWidget}</div>;
};

export default QuickLinkWidget;
