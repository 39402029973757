import React from "react";
import homeScreenJSON from "../DynamicData/homeScreen.json";
import OrderTrackingWidget from "../HomePageWidgets/FooterWidgets/OrderTrackingWidget";
import FreeShippingWidget from "../HomePageWidgets/FooterWidgets/FreeShippingWidget";
import FlexiblePaymentWidget from "../HomePageWidgets/FooterWidgets/FlexiblePaymentWidget";
import CustomerSupportWidget from "../HomePageWidgets/FooterWidgets/CustomerSupportWidget";
import SubscriptionFormWidget from "../HomePageWidgets/FooterWidgets/SubscriptionFormWidget";
import QuickLinkWidget from "../HomePageWidgets/FooterWidgets/QuickLinkWidget";
import CustomerServicesWidget from "../HomePageWidgets/FooterWidgets/CustomerServicesWidget";
import NeedHelpWidget from "../HomePageWidgets/FooterWidgets/NeedHelpWidget";
import FooterContentWidget from "../HomePageWidgets/FooterWidgets/FooterContentWidget";
import FooterLogosWidget from "../HomePageWidgets/FooterWidgets/FooterLogosWidget";
import LogoContentWidget from "../HomePageWidgets/FooterWidgets/LogoContentWidget";
import LogoIconsWidget from "../HomePageWidgets/FooterWidgets/LogoIconsWidget";
import SingleImageWidget from "../HomePageWidgets/BodyWidgets/SingleImageWidget";

const Footer = () => {
  return (
    <footer className="footer ">
      <div className="container pb-4 mt-5">
        <div className="row">
          {homeScreenJSON
            .filter((widget) => widget.Visibility)
            .map((widget) => {
              const { WidgetType, AdditionalProperties } = widget;

              switch (WidgetType) {
                case "LogoContentWidget":
                  return (
                    <div
                      className="col-lg-3 col-md-6 col-sm-12 col-12"
                      key={WidgetType}
                    >
                      <LogoContentWidget
                        AdditionalProperties={AdditionalProperties}
                      />
                    </div>
                  );
                // case "LogoIconsWidget":
                //   return (
                //     <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                //       <LogoIconsWidget
                //         AdditionalProperties={AdditionalProperties}
                //       />
                //     </div>
                //   );
                case "QuickLinksWidget":
                  return (
                    <div
                      className="col-lg-3 col-md-6 col-sm-12 col-12"
                      key={WidgetType}
                    >
                      <QuickLinkWidget
                        AdditionalProperties={AdditionalProperties}
                      />
                    </div>
                  );

                case "CustomerServicesWidget":
                  return (
                    <div
                      className="col-lg-3 col-md-6 col-sm-12 col-12"
                      key={WidgetType}
                    >
                      <CustomerServicesWidget
                        AdditionalProperties={AdditionalProperties}
                      />
                    </div>
                  );

                case "NeedHelpWidget":
                  return (
                    <div
                      className="col-lg-3 col-md-6 col-sm-12 col-12"
                      key={WidgetType}
                    >
                      <NeedHelpWidget
                        AdditionalProperties={AdditionalProperties}
                      />
                    </div>
                  );

                default:
                  return null;
              }
            })}
        </div>
      </div>
      <div className="bottom-bar">
        <div className="container">
          <div className="row">
            {homeScreenJSON
              .filter((widget) => widget.Visibility)
              .map((widget) => {
                const { WidgetType, AdditionalProperties } = widget;

                switch (WidgetType) {
                  case "FooterTitleWidget":
                    return (
                      <div
                        className="col-lg-5 col-md-5 col-sm-12 col-12"
                        key={WidgetType}
                      >
                        <FooterContentWidget
                          AdditionalProperties={AdditionalProperties}
                        />
                      </div>
                    );
                  case "Footerempty":
                    return (
                      <div className="col-lg-4 col-md-4 col-sm-12 col-12"></div>
                    );

                  case "FooterLogosWidget":
                    return (
                      <div
                        className="col-lg-3 col-md-3 col-sm-12 col-12"
                        key={WidgetType}
                      >
                        <FooterLogosWidget
                          AdditionalProperties={AdditionalProperties}
                        />
                      </div>
                    );

                  default:
                    return null;
                }
              })}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
