import React from "react";
import { Link } from "react-router-dom";
import phoneImage from "../../CustomStyle/atcommerce-theme/images/order-phone.webp";

const CustomerSupportWidget = ({ AdditionalProperties }) => {
  const { WidgetContent } = AdditionalProperties;
  const customerSupportWidget = (
    <div className="text-center box">
      {WidgetContent.map((item, index) => {
        const { WidgetTitle, WidgetDescription, ParticularLink } = item;
        return (
          <div key={WidgetTitle}>
            <Link to={ParticularLink}>
              <img src={phoneImage} alt="Image of phone" />
            </Link>
            <h2>{WidgetTitle}</h2>
            <p>{WidgetDescription}</p>
          </div>
        );
      })}
    </div>
  );
  return <div>{customerSupportWidget}</div>;
};

export default CustomerSupportWidget;
