import React from "react";
import { Link } from "react-router-dom";

import clothesImage from "../../CustomStyle/atcommerce-theme/images/clothes-1.webp";

const SingleImageWidget = ({ AdditionalProperties }) => {
  const { WidgetContent, NumberOfImages } = AdditionalProperties;

  const imagesToDisplay = WidgetContent.slice(0, NumberOfImages);

  const singleImageWidget = (
    <>
      {imagesToDisplay.map((item, index) => (
        <div key={index}>
          <Link to={item.ParticularLink}>
            <img
              src={clothesImage}
              alt="Newsletter Subscripe img"
              className="Newsletter-Subscripe-img"
            />
          </Link>
        </div>
      ))}
    </>
  );
  return <div>{singleImageWidget}</div>;
};

export default SingleImageWidget;
