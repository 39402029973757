import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import phoneImage from "../../CustomStyle/atcommerce-theme/images/phone.webp";
import emailImage from "../../CustomStyle/atcommerce-theme/images/envelope-email.webp";
import { useDispatch, useSelector } from "react-redux";
import { guestLogin } from "../../actions/userActions";

const UpperNavbarWidget = ({ AdditionalProperties }) => {
  const { WidgetContent } = AdditionalProperties;
  const userLoginInfo = useSelector((state) => state.userLogin.userLoginInfo);
  return (
    <div className="container container-des">
      <div className="row">
        <div className="col col-mobile">
          <div className="text-start text-mobile">
            {WidgetContent?.filter((item) => item.Visibility).map(
              (widgetItem) => {
                const { WidgetTitle, Content, ParticularLink } = widgetItem;
                switch (WidgetTitle) {
                  case "Phone":
                    return (
                      <a
                        href="tel:0300000000000"
                        target="_blank"
                        className="phone"
                      >
                        <img src={phoneImage} alt="Phone Image" /> 0300000000000
                      </a>
                    );

                  case "Email":
                    return (
                      <a href={`mailto:${Content}`} className="email">
                        <img src={emailImage} alt="Image of Email Icon" />{" "}
                        {Content}
                      </a>
                    );
                  default:
                    return null;
                }
              }
            )}
          </div>
        </div>
        <div className="col col-get-flex">
          {WidgetContent?.filter((item) => item.WidgetTitle === "Sale").map(
            (widgetItem) => {
              const { WidgetTitle, Content } = widgetItem;
              return (
                <div className="text-center" key={WidgetTitle}>
                  <div className="get-offer">{Content}</div>
                </div>
              );
            }
          )}
        </div>
        {userLoginInfo ? (
          <>
            <div className="col col-sign">
              <div className="text-end text-mobile">
                <div className="link">
                  <strong>Hi! {userLoginInfo?.username}</strong>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="col col-sign">
            <div className="text-end text-mobile">
              {WidgetContent?.filter((item) => item.Visibility).map(
                (widgetItem) => {
                  const { WidgetTitle, Content, ParticularLink } = widgetItem;
                  switch (WidgetTitle) {
                    case "SignIn":
                      return (
                        <Link
                          to={`${ParticularLink}/signin`}
                          className="link"
                          key={WidgetTitle}
                        >
                          {Content}
                        </Link>
                      );

                    case "Register":
                      return (
                        <Link
                          to={`${ParticularLink}/register`}
                          className="link"
                          key={WidgetTitle}
                        >
                          {Content}
                        </Link>
                      );
                    default:
                      return null;
                  }
                }
              )}
            </div>
          </div>
        )}
      </div>
      {/* <Link to="/demo">Demo</Link> */}
    </div>
  );
};

export default UpperNavbarWidget;
