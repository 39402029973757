import React from "react";
import { Link } from "react-router-dom";
import fastDeliveryImage from "../../CustomStyle/atcommerce-theme/images/Fast-Delivery-Outline.webp";

const FreeShippingWidget = ({ AdditionalProperties }) => {
  const { WidgetContent } = AdditionalProperties;
  const freeShippingWidget = (
    <div className="text-center box">
      {WidgetContent.map((item, index) => {
        const { WidgetTitle, WidgetDescription, ParticularLink } = item;
        return (
          <div key={WidgetTitle}>
            <Link to={ParticularLink}>
              <img src={fastDeliveryImage} alt="Faster Dekivery Image" />
            </Link>
            <h2>{WidgetTitle}</h2>
            <p>{WidgetDescription}</p>
          </div>
        );
      })}
    </div>
  );
  return <div>{freeShippingWidget}</div>;
};

export default FreeShippingWidget;
