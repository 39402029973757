export const GET_SHIPPING_ADDRESS_REQUEST = "GET_SHIPPING_ADDRESS_REQUEST";
export const GET_SHIPPING_ADDRESS_SUCCESS = "GET_SHIPPING_ADDRESS_SUCCESS";
export const GET_SHIPPING_ADDRESS_FAIL = "GET_SHIPPING_ADDRESS_FAIL";

export const SAVE_SHIPPING_ADDRESS_REQUEST = "SAVE_SHIPPING_ADDRESS_REQUEST";
export const SAVE_SHIPPING_ADDRESS_SUCCESS = "SAVE_SHIPPING_ADDRESS_SUCCESS";
export const SAVE_SHIPPING_ADDRESS_FAIL = "SAVE_SHIPPING_ADDRESS_FAIL";

export const SELECT_BILLING_ADDRESS_REQUEST = "SELECT_BILLING_ADDRESS_REQUEST";
export const SELECT_BILLING_ADDRESS_SUCCESS = "SELECT_BILLING_ADDRESS_SUCCESS";
export const SELECT_BILLING_ADDRESS_FAIL = "SELECT_BILLING_ADDRESS_FAIL";

export const SELECT_SHIPPING_ADDRESS_REQUEST =
  "SELECT_SHIPPING_ADDRESS_REQUEST";
export const SELECT_SHIPPING_ADDRESS_SUCCESS =
  "SELECT_SHIPPING_ADDRESS_SUCCESS";
export const SELECT_SHIPPING_ADDRESS_FAIL = "SELECT_SHIPPING_ADDRESS_FAIL";

export const GET_ADDRESS_LIST_REQUEST = "GET_ADDRESS_LIST_REQUEST";
export const GET_ADDRESS_LIST_SUCCESS = "GET_ADDRESS_LIST_SUCCESS";
export const GET_ADDRESS_LIST_FAIL = "GET_ADDRESS_LIST_FAIL";

export const GET_CHECKOUT_INFO_REQUEST = "GET_CHECKOUT_INFO_REQUEST";
export const GET_CHECKOUT_INFO_SUCCESS = "GET_CHECKOUT_INFO_SUCCESS";
export const GET_CHECKOUT_INFO_FAIL = "GET_CHECKOUT_INFO_FAIL";

export const SAVE_PAYMENT_METHOD_REQUEST = "SAVE_PAYMENT_METHOD_REQUEST";
export const SAVE_PAYMENT_METHOD_SUCCESS = "SAVE_PAYMENT_METHOD_SUCCESS";
export const SAVE_PAYMENT_METHOD_FAIL = "SAVE_PAYMENT_METHOD_FAIL";

export const AVAILABLE_SHIPPING_METHOD_REQUEST = "SAVE_SHIPPING_METHOD_REQUEST";
export const AVAILABLE_SHIPPING_METHOD_SUCCESS = "SAVE_SHIPPING_METHOD_SUCCESS";
export const AVAILABLE_SHIPPING_METHOD_FAIL = "SAVE_SHIPPING_METHOD_FAIL";

export const SAVE_SHIPPING_METHOD_REQUEST = "SAVE_SHIPPING_METHOD_REQUEST";
export const SAVE_SHIPPING_METHOD_SUCCESS = "SAVE_SHIPPING_METHOD_SUCCESS";
export const SAVE_SHIPPING_METHOD_FAIL = "SAVE_SHIPPING_METHOD_FAIL";

export const GET_PROVINCE_FROM_COUNTRY_REQUEST =
  "GET_PROVINCE_FROM_COUNTRY_REQUEST";
export const GET_PROVINCE_FROM_COUNTRY_SUCCESS =
  "GET_PROVINCE_FROM_COUNTRY_SUCCESS";
export const GET_PROVINCE_FROM_COUNTRY_FAIL = "GET_PROVINCE_FROM_COUNTRY_FAIL";
