import React from "react";
import { Spinner } from "react-bootstrap";

const Loader = () => {
  return (
    <div className="spinner-grow text-dark" role="status">
      {/* <span className="visually-hidden">Loading...</span> */}
    </div>
  );
};

export default Loader;
