import {
  CART_ADD_ITEM,
  CART_CLEAR_ITEMS,
  CART_DECREASE_ITEM,
  CART_UPDATE_ITEMS_REQUEST,
  CART_UPDATE_ITEMS_SUCCESS,
  CART_UPDATE_ITEMS_FAIL,
  GET_CART_ITEMS_REQUEST,
  GET_CART_ITEMS_SUCCESS,
  GET_CART_ITEMS_FAIL,
  ADD_FAVOURITE_TO_REQUEST,
  ADD_FAVOURITE_TO_SUCCESS,
  ADD_FAVOURITE_TO_FAIL,
  REMOVE_FAVOURITE_TO_REQUEST,
  REMOVE_FAVOURITE_TO_SUCCESS,
  REMOVE_FAVOURITE_TO_FAIL,
  LIST_OF_FAVOURITE_TO_REQUEST,
  LIST_OF_FAVOURITE_TO_SUCCESS,
  LIST_OF_FAVOURITE_TO_FAIL,
} from "../constants/cartConstants";
const getLocalData = () => {
  let localCartData = localStorage.getItem("cartItems");
  if (localCartData === []) {
    return [];
  } else {
    return JSON.parse(localCartData);
  }
};

export const cartReducer = (state = { cartItems: [] }, action) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      return { cartItems: action.payload };

    default:
      return state;
  }
};

export const cartItemsReducer = (
  state = { cartItems: getLocalData(), shippingAddress: {} },
  action
) => {
  switch (action.type) {
    case GET_CART_ITEMS_REQUEST:
      return { loading: true };
    case GET_CART_ITEMS_SUCCESS:
      return { loading: false, cartItems: action.payload };
    case GET_CART_ITEMS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const cartUpdateReducer = (
  state = { cartItems: getLocalData(), shippingAddress: {} },
  action
) => {
  switch (action.type) {
    case CART_UPDATE_ITEMS_REQUEST:
      return { loading: true };
    case CART_UPDATE_ITEMS_SUCCESS:
      return { loading: false, success: true };
    case CART_UPDATE_ITEMS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const addFavouriteToCartReducer = (
  state = { favouriteProduct: {} },
  action
) => {
  switch (action.type) {
    case ADD_FAVOURITE_TO_REQUEST:
      return { loading: true };
    case ADD_FAVOURITE_TO_SUCCESS:
      return {
        loading: false,

        favouriteProduct: action.payload,
      };
    case ADD_FAVOURITE_TO_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const removeFavouriteToCartReducer = (
  state = { removedFavouredProduct: {} },
  action
) => {
  switch (action.type) {
    case REMOVE_FAVOURITE_TO_REQUEST:
      return { loading: true };
    case REMOVE_FAVOURITE_TO_SUCCESS:
      return { loading: false, removedFavouredProduct: action.payload };
    case REMOVE_FAVOURITE_TO_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const favouriteToCartListReducer = (
  state = { removedFavouredProduct: {} },
  action
) => {
  switch (action.type) {
    case LIST_OF_FAVOURITE_TO_REQUEST:
      return { loading: true };
    case LIST_OF_FAVOURITE_TO_SUCCESS:
      return { loading: false, fevouriteProductList: action.payload };
    case LIST_OF_FAVOURITE_TO_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
