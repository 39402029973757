import React from "react";
import phoneImage from "../../CustomStyle/atcommerce-theme/images/phone.webp";
import mobileImage from "../../CustomStyle/atcommerce-theme/images/mobile-user-icon.webp";

const MobileUpperNavbarWidget = ({ AdditionalProperties }) => {
  const { WidgetContent } = AdditionalProperties;
  return (
    <div className="row">
      <div className="col-lg-5 col-md-12 col-sm-12 col-12">
        <div className="text-left text-mobile d-flex justify-content-between">
          <div className="mobil-s">
            <a href="#" className="phone">
              <img src={phoneImage} alt="Image of Phone" /> +92 332 3652322
            </a>
            <span className="get-offer">Get flat 20% Off!</span>
          </div>
          <div className="mobile-r">
            <a href="#" className="link">
              <img src={mobileImage} alt="Image of Mobile" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileUpperNavbarWidget;
