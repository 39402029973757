import React from "react";

const MediumSizeSvg = () => {
  return (
    <div>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="29"
          height="29"
          fill="#C3C3C3"
          stroke="#C3C3C3"
        />
        <path
          d="M20.4736 9.96V21H19.3536V12.184L15.4176 21H14.6016L10.6656 12.216V21H9.54563V9.96H10.7296L15.0016 19.528L19.2736 9.96H20.4736Z"
          fill="#323232"
        />
      </svg>
    </div>
  );
};

export default MediumSizeSvg;
