import React from "react";

const XLargeSizeSvg = () => {
  return (
    <div>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="30" height="30" fill="#E1E2E6" />
        <path
          d="M12.7651 15.416L16.1571 21H14.9091L12.0611 16.328L9.35713 21H8.10913L11.4851 15.368L8.10913 9.816H9.35713L12.1891 14.472L14.9251 9.816H16.1731L12.7651 15.416ZM19.2125 20.088H23.2285V21H18.0925V9.816H19.2125V20.088Z"
          fill="#AFADAD"
        />
      </svg>
    </div>
  );
};

export default XLargeSizeSvg;
