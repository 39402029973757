import React from "react";
import threebarImage from "../../CustomStyle/atcommerce-theme/images/navbar-threebar.webp";
import logoImage from "../../CustomStyle/atcommerce-theme/images/atcommerce-logo-mobilenew.png";
import SearchImage from "../../CustomStyle/atcommerce-theme/images/mobile/Search-mobile.png";
import venImage from "../../CustomStyle/atcommerce-theme/images/mobile/ven.png";
import heartImage from "../../CustomStyle/atcommerce-theme/images/mobile/heart.png";
import bagImage from "../../CustomStyle/atcommerce-theme/images/mobile/bag.png";

// /mobile/Search-mobile.png./images/mobile/heart.png

const MobileMiddleNavbarWidget = () => {
  return (
    <>
      <div className="bar">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#navbarOffcanvas"
          aria-controls="navbarOffcanvas"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <img src={threebarImage} alt="Three Image" />
        </button>

        <a className="navbar-brand" href="#">
          <img src={logoImage} alt="Logo Image" />
        </a>
      </div>
      <div className="top cart-button">
        <a href="#" className="up-button">
          <img src={SearchImage} alt="Search Image" />
        </a>
        <a href="#" className="up-button">
          <img src={venImage} alt="Ven Image" />
        </a>
        <a href="#" className="up-button">
          <img src={heartImage} alt="Heart Image" />
        </a>
        <a href="#" className="up-button bag">
          <img src={bagImage} alt="Bag Image" />
        </a>
      </div>
    </>
  );
};

export default MobileMiddleNavbarWidget;
