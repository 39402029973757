import React from "react";
import { Link } from "react-router-dom";
import atcommerceLogoImage from "../../CustomStyle/atcommerce-theme/images/atcommerce-logo.png";
import facebookImage from "../../CustomStyle/atcommerce-theme/images/facebook.png";
import instagramImage from "../../CustomStyle/atcommerce-theme/images/instagram.png";
import twitterImage from "../../CustomStyle/atcommerce-theme/images/twitter.png";
import youtubeImage from "../../CustomStyle/atcommerce-theme/images/youtube.png";
import snapchatImage from "../../CustomStyle/atcommerce-theme/images/snapchat.png";

const LogoContentWidget = ({ AdditionalProperties }) => {
  const { WidgetContent } = AdditionalProperties;
  const logoContentWidget = (
    <div className="text-left f-links">
      {WidgetContent.map((item) => {
        const { WidgetTitle, WidgetDescription, LogoWidgetContent } = item;
        return (
          <div key={WidgetTitle}>
            <a>
              <img src={atcommerceLogoImage} alt=" image of Sale" />
            </a>

            <p className="mt-5 company-detail">{WidgetDescription}</p>
            <div className="mt-5 company-detail">
              {LogoWidgetContent?.filter((item) => item.Visibility).map(
                (widgetItem) => {
                  const { WidgetType, ParticularLink } = widgetItem;
                  switch (WidgetType) {
                    case "FacebookIcon":
                      return (
                        <a
                          href={ParticularLink}
                          target="_blank"
                          className="pe-2"
                        >
                          <img src={facebookImage} alt=" image of facebook" />
                        </a>
                      );
                    case "InstagramIcon":
                      return (
                        <a
                          href={ParticularLink}
                          target="_blank"
                          className="px-2"
                        >
                          <img src={instagramImage} alt=" image of instagram" />
                        </a>
                      );
                    case "TwitterIcon":
                      return (
                        <a
                          href={ParticularLink}
                          target="_blank"
                          className="px-2"
                        >
                          <img src={twitterImage} alt=" image of twitter" />
                        </a>
                      );
                    case "YoutubeIcon":
                      return (
                        <a
                          href={ParticularLink}
                          target="_blank"
                          className="px-2"
                        >
                          <img src={youtubeImage} alt=" image of youtube" />
                        </a>
                      );
                    case "BellIcon":
                      return (
                        <a href={ParticularLink} className="px-2">
                          <img src={snapchatImage} alt=" image of snapchat" />
                        </a>
                      );
                    case "LinkdinIcon":
                      return (
                        <a
                          href={ParticularLink}
                          className="px-2"
                          target="_blank"
                        >
                          <img src={snapchatImage} alt=" image of snapchat" />
                        </a>
                      );

                    default:
                      return null;
                  }
                }
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
  return <div>{logoContentWidget}</div>;
};

export default LogoContentWidget;
