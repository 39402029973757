import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  listOfBestSeller,
  ProductCategoriesDetailAcrion,
} from "../actions/productActions";

import Loader from "../components/Loader";
import Message from "../components/Message";
import heartImage from "../CustomStyle/atcommerce-theme/images/product-detail-page/heart-w.webp";

import { Link, useLocation, useParams } from "react-router-dom";
import PeachColorSvg from "../Icons/Colors/PeachColorSvg";
import DarkBlueColorSvg from "../Icons/Colors/DarkBlueColorSvg";
import OrangeColorSvg from "../Icons/Colors/OrangeColorSvg";
import PurpleColorSvg from "../Icons/Colors/PurpleColorSvg";
import LargeSizeSvg from "../Icons/Size/LargeSizeSvg";
import MediumSizeSvg from "../Icons/Size/MediumSizeSvg";
import SmallSizeSvg from "../Icons/Size/SmallSizeSvg";
import XLargeSizeSvg from "../Icons/Size/XLargeSizeSvg";
import HeartIconSvg from "../Icons/Header_Icons/HeartIconSvg";
import threeImage from "../CustomStyle/atcommerce-theme/images/three-col.png";
import fourImage from "../CustomStyle/atcommerce-theme/images/four-col.png";
import threeListImage from "../CustomStyle/atcommerce-theme/images/three-listcol.png";
import filterClose from "../CustomStyle/atcommerce-theme/images/fillter-close.png";

import decreaseImage from "../CustomStyle/atcommerce-theme/images/product-detail-page/decrease.webp";
import increaseImage from "../CustomStyle/atcommerce-theme/images/product-detail-page/plus.webp";
import heartEmptyImage from "../CustomStyle/atcommerce-theme/images/heart-empty.png";

import heartFillImage from "../CustomStyle/atcommerce-theme/images/heart-filled.png";
import filterIconImage from "../CustomStyle/atcommerce-theme/images/Filters-icon.png";
import {
  addFavouriteToCart,
  addToCart,
  favouriteToCartlistAction,
  getCartItems,
  removefavouriteToCartlistAction,
} from "../actions/cartActions";
const AllBestSellers = ({ categoryNameInUrl }) => {
  const { search } = useLocation();
  const nameInUrl = new URLSearchParams(search).get("name");
  // const [cartError, setCartError] = useState("");
  const params = useParams();
  const [qty, setqty] = useState(1);
  const [selectedColor, setSelectedColor] = useState("Black");
  const [selectedSize, setSelectedSize] = useState("Medium");
  const [favouriteProductError, setFavouriteProduct] = useState("");
  const bestSellers = useSelector((state) => state.bestSellers);

  const {
    loading: loadingBestSellers,
    error: errorBestSellers,
    allBestSeller,
  } = bestSellers;
  // console.log(allBestSeller);
  const totalProducts = allBestSeller?.length;

  const { id: categoryProductId } = params;
  const dispatch = useDispatch();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedFilterNames, setSelectedFilterNames] = useState([]);
  const [openFilters, setOpenFilters] = useState([]);

  const userInfo = useSelector((state) => state.userGuest.userInfo);

  const userLogin = useSelector((state) => state.userLogin);
  const { userLoginInfo } = userLogin;
  const user_guids = userLoginInfo
    ? userLoginInfo?.user_guid
    : userInfo?.user_guid;

  const listOfFavouriteItemToCart = useSelector(
    (state) => state.listOfFavouriteItemToCart
  );
  const {
    loading: loadingFevouriteProductList,
    error: errorFevouriteProductList,
    fevouriteProductList,
  } = listOfFavouriteItemToCart;

  const productCategoryDetail = useSelector(
    (state) => state.productCategoryDetail
  );
  const {
    loading: loadingProductDetail,
    error: errorProductDetail,
    categoryDetail,
  } = productCategoryDetail;

  let categoryDetailData = categoryDetail?.catalog_products_model;
  let categoryProducts = categoryDetailData?.products;
  // console.log(categoryProducts);

  // const totalProducts = categoryProducts?.length;

  // console.log(categoryDetailData);
  const [startPrice, setStartPrice] = useState(
    categoryDetailData?.price_range_filter?.available_price_range?.from || 0
  );
  const [endPrice, setEndPrice] = useState(
    categoryDetailData?.price_range_filter?.available_price_range?.to || 5000000
  );
  const [selectedValue, setSelectedValue] = useState(totalProducts || 10);

  const filterAttributes =
    categoryDetail?.catalog_products_model?.specification_filter?.attributes;

  const [isFavouriteMap, setIsFavouriteMap] = useState(() => {
    const mapFromStorage = JSON.parse(localStorage.getItem("isFavouriteMap"));

    if (mapFromStorage) {
      return mapFromStorage;
    } else {
      return {};
    }
  });

  useEffect(() => {
    // let start = startPrice;
    // let end = endPrice;
    dispatch(favouriteToCartlistAction(user_guids));
    dispatch(listOfBestSeller());
  }, [dispatch, categoryProductId, selectedIds, startPrice, endPrice]);

  const incrementqty = () => {
    setqty(qty + 1);
  };

  const decrementqty = () => {
    if (qty > 1) {
      setqty(qty - 1);
    }
  };
  function handleColorClick(color) {
    setSelectedColor(color);
  }
  function handleClick(size) {
    setSelectedSize(size);
  }
  const [showCartButtonId, setShowCartButtonId] = useState(null);
  const [isQuickViewHidden, setIsQuickViewHidden] = useState(false);
  const showOrHideDiv = (e, itemId) => {
    e.preventDefault();
    setShowCartButtonId(itemId);
    let v = document.getElementById(`showOrHide-${itemId}`);
    if (v.style.display === "block") {
      v.style.display = "none";
      setIsQuickViewHidden(false);
    } else {
      v.style.display = "block";
      setIsQuickViewHidden(true);
    }
  };

  const cancelHandler = (e, itemId) => {
    e.preventDefault();
    setShowCartButtonId(null);
    let v = document.getElementById(`showOrHide-${itemId}`);
    v.style.display = "none";
    setIsQuickViewHidden(false);
  };
  const toggleFilter = (index) => {
    const isOpen = openFilters.includes(index);
    if (isOpen) {
      setOpenFilters(openFilters.filter((i) => i !== index));
    } else {
      setOpenFilters([...openFilters, index]);
    }
  };

  const addToCartHandler = (productId) => {
    // if (!userLoginInfo) {
    //   setCartError("Please Login for Add Items in Cart");
    // } else {
    // console.log(productId);
    dispatch(addToCart(productId, qty)).then(() => dispatch(getCartItems()));
    // }
  };
  const handleSelectIdAndName = (id, name) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
      setSelectedFilterNames(
        selectedFilterNames.filter((selectedName) => selectedName !== name)
      );
    } else {
      setSelectedIds([...selectedIds, id]);
      setSelectedFilterNames([...selectedFilterNames, name]);
    }
  };

  const handleClearFilter = () => {
    setSelectedIds([]);
    setSelectedFilterNames([]);
  };

  const addFavouriteItemToCart = useSelector(
    (state) => state.addFavouriteItemToCart
  );
  const {
    loading: loadingFavouriteProduct,
    error: errorFavouriteProduct,
    favouriteProduct,
  } = addFavouriteItemToCart;

  const clearAllFiltersHandler = () => {};

  const addFavouriteToCartHandler = (e, productID) => {
    e.preventDefault();
    const matchedItem = fevouriteProductList?.items.find(
      (favItem) => favItem.product_id === productID
    );
    // console.log(matchedItem);
    if (matchedItem) {
      dispatch(removefavouriteToCartlistAction(matchedItem.id)).then(() =>
        dispatch(favouriteToCartlistAction(user_guids ? user_guids : null))
      );
    } else {
      dispatch(addFavouriteToCart(productID)).then(() =>
        dispatch(favouriteToCartlistAction(user_guids ? user_guids : null))
      );
    }
    setIsFavouriteMap((prevState) => {
      const newState = { ...prevState, [productID]: !matchedItem };
      localStorage.setItem("isFavouriteMap", JSON.stringify(newState));
      return newState;
    });
  };
  const options = Array.from({ length: totalProducts }, (_, i) => i + 1);

  return (
    <section className="shop-By-Category pt-4 mt-5">
      <div className="container">
        <div className="text-center">
          <h2 className="top-header pb-3">{nameInUrl}</h2>
        </div>
        <div className="row">
          <div className="col-12 col-lg-3">
            <div className="accordion filters pt-3" id="accordionExample">
              <div className="accordion-item">
                <h2
                  className="accordion-header accordion-header-main"
                  id="headingOne"
                >
                  <button
                    className="accordion-button collapsed pt-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <img src={filterIconImage} alt="Filters Image" /> Filters
                  </button>
                </h2>
                <div>
                  <div>
                    {selectedIds.length > 0 && (
                      <button
                        className="p-1 me-2 my-2"
                        onClick={handleClearFilter}
                      >
                        Clear All X
                      </button>
                    )}
                    {selectedFilterNames.map((name, index) => (
                      <button
                        className="p-1 me-2 my-2"
                        key={selectedIds[index]}
                        onClick={() =>
                          handleSelectIdAndName(selectedIds[index], name)
                        }
                      >
                        {name} <span>X</span>
                      </button>
                    ))}
                  </div>
                  <div>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      {filterAttributes?.map((item, index) => {
                        const isOpen = openFilters.includes(index);
                        return (
                          <div
                            className="accordion-body accordion-body-sub"
                            key={item.id}
                          >
                            <div
                              className="accordion"
                              id={`subAccordion${index}`}
                            >
                              <div className="accordion-item border border-top-0 border-start-0 border-end-0">
                                <h2
                                  className="accordion-header accordion-header-main"
                                  id={`subHeading${index}`}
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    onClick={() => toggleFilter(index)}
                                    data-bs-target={`#subCollapse${index}`}
                                    aria-expanded={isOpen}
                                    data-bs-toggle="collapse"
                                    aria-controls={`subCollapse${index}`}
                                  >
                                    {item.name}
                                  </button>
                                </h2>
                                <div
                                  id={`subCollapse${index}`}
                                  className={`accordion-collapse collapse ${
                                    isOpen ? "true" : ""
                                  }`}
                                  aria-labelledby={`subHeading${index}`}
                                  data-bs-parent={`#subAccordion${index}`}
                                >
                                  {item.values.map((subItem) => {
                                    return (
                                      <div
                                        className="accordion-body accordion-body-sub"
                                        key={subItem.id}
                                      >
                                        <button
                                          className={
                                            "bg-white" +
                                            (selectedIds.includes(subItem.id)
                                              ? "selected"
                                              : "")
                                          }
                                          onClick={() =>
                                            handleSelectIdAndName(
                                              subItem.id,
                                              subItem.name
                                            )
                                          }
                                        >
                                          {subItem.name}
                                        </button>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div
                        className="accordion accordion-price"
                        id="subAccorionPrice"
                      >
                        <div className="accordion-item">
                          <h2
                            className="accordion-header accordion-header-main"
                            id="subHeadingPrice"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapsePrice"
                              aria-expanded="true"
                              aria-controls="collapsePrice"
                            >
                              Price
                            </button>
                          </h2>
                          <div
                            id="collapsePrice"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingPrice"
                            data-bs-parent="#subAccorionPrice"
                          >
                            <div className="accordion-body accordion-body-sub">
                              <form class=" py-3">
                                <div className="row">
                                  <div className="col-sm-12 col-md-5">
                                    <div className="form-group">
                                      <label>Start Price:</label>
                                      <input
                                        className="form-control"
                                        type="number"
                                        value={startPrice}
                                        onChange={(e) =>
                                          setStartPrice(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-2 fs-4 pt-3 fw-bold d-none -d-sm-none d-md-block">
                                    {" "}
                                    _{" "}
                                  </div>
                                  <div className="col-sm-12 col-md-5">
                                    <div className="form-group">
                                      <label>End Price:</label>
                                      <input
                                        className="form-control"
                                        type="number"
                                        value={endPrice}
                                        onChange={(e) =>
                                          setEndPrice(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-9 mt-3">
            <div className="main-product d-flex justify-content-between">
              <div className="threecol">
                <a>
                  <img src={threeImage} alt="Three Images" />
                </a>
                <a>
                  <img src={fourImage} alt="Four Images" />
                </a>
                <a>
                  <img src={threeListImage} alt="List Images" />
                </a>
              </div>
              <div className="threecol">
                <div className="total-product d-flex">
                  {totalProducts} Products{" "}
                  <Link
                    onClick={clearAllFiltersHandler}
                    className="pe-2 ps-2 show-all"
                  >
                    Show All
                  </Link>{" "}
                  | <span className="pe-2 ps-2 sortby">Products Per Page</span>
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {selectedValue || "All"}
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {options.map((option) => (
                        <li key={option}>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => setSelectedValue(option)}
                          >
                            {option}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <span className="pe-2 ps-2 sortby">Sort by</span>
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Featured
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          Drop Down Menu Design is pending
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* {cartError && <Message variant="danger">{cartError}</Message>} */}

            <div className="row mt-2">
              {allBestSeller && allBestSeller.length === 0 ? (
                <h2>No Item Found</h2>
              ) : loadingBestSellers ? (
                <Loader></Loader>
              ) : errorBestSellers ? (
                <Message variant="danger">{errorProductDetail}</Message>
              ) : (
                <>
                  {allBestSeller &&
                    allBestSeller
                      ?.map((item) => {
                        const isFavourite =
                          isFavouriteMap[item?.Id] ??
                          !!fevouriteProductList?.items.find(
                            (favItem) => favItem.product_id === item?.Id
                          );

                        return (
                          <div className="col col-4 mt-2" key={item.Id}>
                            <div className="product-block">
                              <div className="product-img">
                                <span
                                  onClick={(e) =>
                                    addFavouriteToCartHandler(e, item.Id)
                                  }
                                >
                                  {isFavourite ? (
                                    <a className="pro-heart">
                                      <img
                                        src={heartFillImage}
                                        alt="Image of heart"
                                      />
                                    </a>
                                  ) : (
                                    <a className="pro-heart">
                                      <img
                                        src={heartEmptyImage}
                                        alt="Image of heart"
                                      />
                                    </a>
                                  )}
                                </span>

                                <Link
                                  to={`/product/${item.Id}?categoryNameInUrl=${categoryNameInUrl}&name=${item.name}`}
                                >
                                  <div className="product-image">
                                    <img
                                      src={item?.DefaultPictureModel?.ImageUrl}
                                      alt={item?.DefaultPictureModel?.ImageUrl}
                                    />
                                  </div>
                                </Link>
                                <div
                                  className="showOrHide"
                                  id={`showOrHide-${item.Id}`}
                                  style={{ display: "none" }}
                                >
                                  <div className="code-stock">
                                    Color:
                                    {""}
                                    <span className="product-code">
                                      {" "}
                                      {selectedColor}
                                    </span>
                                  </div>
                                  <ul
                                    className="color-Peach mt- mb-2"
                                    key={selectedColor}
                                  >
                                    <li
                                      onClick={() =>
                                        handleColorClick("DarkBlue")
                                      }
                                    >
                                      <DarkBlueColorSvg />
                                    </li>
                                    <li
                                      onClick={() => handleColorClick("Orange")}
                                    >
                                      <OrangeColorSvg />
                                    </li>
                                    <li
                                      onClick={() => handleColorClick("Peach")}
                                    >
                                      <PeachColorSvg />
                                    </li>
                                    <li
                                      onClick={() => handleColorClick("Purple")}
                                    >
                                      <PurpleColorSvg />
                                    </li>
                                  </ul>
                                  <span className="code-stock mt-2">
                                    Size :{" "}
                                    <span className="product-code">
                                      {" "}
                                      {selectedSize}
                                    </span>
                                    <ul
                                      className="product-size mt-2"
                                      key={selectedSize}
                                    >
                                      <li onClick={() => handleClick("Small")}>
                                        <SmallSizeSvg />
                                      </li>
                                      <li onClick={() => handleClick("Medium")}>
                                        <MediumSizeSvg />
                                      </li>
                                      <li onClick={() => handleClick("Large")}>
                                        <LargeSizeSvg />
                                      </li>
                                      <li
                                        onClick={() =>
                                          handleClick("ExtraLarge")
                                        }
                                      >
                                        <XLargeSizeSvg />
                                      </li>
                                    </ul>
                                  </span>
                                  <div className="text-center mt-2">
                                    Quantity:
                                  </div>
                                  <div className="code-stock mt-1 d-flex justify-content-center">
                                    <div className="clearfix"></div>
                                    <span className="decrease-increase d-flex justify-content-start">
                                      <div
                                        className="value-button"
                                        onClick={decrementqty}
                                      >
                                        <img
                                          src={decreaseImage}
                                          alt="Decrease Quantity of image"
                                        />
                                      </div>

                                      <div className="quantity">{qty}</div>
                                      <div
                                        className="value-button"
                                        onClick={incrementqty}
                                      >
                                        <img
                                          src={increaseImage}
                                          alt="Increase Quantity of image"
                                        />
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div className="pro-hoverflex">
                                  <div className="pro-button">
                                    <div className="d-flex justify-content-between">
                                      {showCartButtonId === item.Id ? (
                                        <>
                                          <Link
                                            className="title"
                                            onClick={() =>
                                              addToCartHandler(item.Id)
                                            }
                                          >
                                            Add to Cart
                                          </Link>
                                          <Link
                                            className="price"
                                            onClick={(e) =>
                                              cancelHandler(e, item.Id)
                                            }
                                          >
                                            Cancel
                                          </Link>
                                        </>
                                      ) : (
                                        <>
                                          <Link
                                            className="title"
                                            onClick={(e) =>
                                              showOrHideDiv(e, item.Id)
                                            }
                                            id={`showOrHide-${item.Id}`}
                                          >
                                            Shop Now
                                          </Link>

                                          {!isQuickViewHidden && (
                                            <Link
                                              to={`/product/${item.Id}?categoryNameInUrl=${categoryNameInUrl}&name=${item.name}`}
                                              className="price"
                                            >
                                              Quick View
                                            </Link>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="pro-title text-center mt-3">
                                <a className="title">{item?.Name}</a>
                                <a className="price">
                                  {item.ProductPrice.Price}
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })
                      .slice(0, selectedValue)}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AllBestSellers;
