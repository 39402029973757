export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_MODEL_REQUEST = "USER_REGISTER_MODEL_REQUEST";
export const USER_REGISTER_MODEL_SUCCESS = "USER_REGISTER_MODEL_SUCCESS";
export const USER_REGISTER_MODEL_FAIL = "USER_REGISTER_MODEL_FAIL";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_RESET = "USER_LIST_RESET";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const GUEST_LOGIN_REQUEST = "GUEST_LOGIN_REQUEST";
export const GUEST_LOGIN_SUCCESS = "GUEST_LOGIN_SUCCESS";
export const GUEST_LOGIN_FAIL = "GUEST_LOGIN_FAIL";

export const USER_PASSWORD_RECOVER_REQUEST = "USER_PASSWORD_RECOVER_REQUEST";
export const USER_PASSWORD_RECOVER_SUCCESS = "USER_PASSWORD_RECOVER_SUCCESS";
export const USER_PASSWORD_RECOVER_FAIL = "USER_PASSWORD_RECOVER_FAIL";

export const USER_PASSWORD_RESET_REQUEST = "USER_PASSWORD_RESET_REQUEST";
export const USER_PASSWORD_RESET_SUCCESS = "USER_PASSWORD_RESET_SUCCESS";
export const USER_PASSWORD_RESET_FAIL = "USER_PASSWORD_RESET_FAIL";

export const NEWSLETTER_SUBSCRIPTION_REQUEST =
  "NEWSLETTER_SUBSCRIPTION_REQUEST";
export const NEWSLETTER_SUBSCRIPTION_SUCCESS =
  "NEWSLETTER_SUBSCRIPTION_SUCCESS";
export const NEWSLETTER_SUBSCRIPTION_FAIL = "NEWSLETTER_SUBSCRIPTION_FAIL";

export const USER_PROFILE_IMAGE_REQUEST = "USER_PROFILE_IMAGE_REQUEST";
export const USER_PROFILE_IMAGE_SUCCESS = "USER_PROFILE_IMAGE_SUCCESS";
export const USER_PROFILE_IMAGE_FAIL = "USER_PROFILE_IMAGE_FAIL";

export const USER_UPLOAD_PROFILE_IMAGE_REQUEST =
  "USER_UPLOAD_PROFILE_IMAGE_REQUEST";
export const USER_UPLOAD_PROFILE_IMAGE_SUCCESS =
  "USER_UPLOAD_PROFILE_IMAGE_SUCCESS";
export const USER_UPLOAD_PROFILE_IMAGE_FAIL = "USER_UPLOAD_PROFILE_IMAGE_FAIL";

export const USER_REMOVE_PROFILE_IMAGE_REQUEST =
  "USER_REMOVE_PROFILE_IMAGE_REQUEST";
export const USER_REMOVE_PROFILE_IMAGE_SUCCESS =
  "USER_REMOVE_PROFILE_IMAGE_SUCCESS";
export const USER_REMOVE_PROFILE_IMAGE_FAIL = "USER_REMOVE_PROFILE_IMAGE_FAIL";

export const USER_COMPLETE_INFO_REQUEST = "USER_COMPLETE_INFO_REQUEST";
export const USER_COMPLETE_INFO_SUCCESS = "USER_COMPLETE_INFO_SUCCESS";
export const USER_COMPLETE_INFO_FAIL = "USER_COMPLETE_INFO_FAIL";
