import React from "react";

const DarkBlueColorSvg = () => {
  return (
    <div>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="30" height="30" rx="15" fill="#FF5F00" />
        <rect width="30" height="30" rx="15" fill="#222357" />
      </svg>
    </div>
  );
};

export default DarkBlueColorSvg;
