import React, { useEffect, useRef, useState } from "react";
import newSettlerImage from "../../CustomStyle/atcommerce-theme/images/new-arrivals.png";
import { newsLetterSubscriptionAction } from "../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from "../../components/Loader";

const SubscriptionFormWidget = ({ AdditionalProperties }) => {
  const { WidgetContent } = AdditionalProperties;
  const dispatch = useDispatch();
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });
  const newSettlerSubscriptionStore = useSelector(
    (state) => state.newSettlerSubscriptionStore
  );
  const {
    loading: loadingSubscription,
    error: errorSubscription,
    newsLetterSubscriptionResponse,
  } = newSettlerSubscriptionStore;
  // console.log(newsLetterSubscriptionResponse);

  useEffect(() => {
    if (errorSubscription || newsLetterSubscriptionResponse) {
      setShowMessage(true);
      setMessage(errorSubscription || newsLetterSubscriptionResponse);
      setTimeout(() => {
        setShowMessage(false);
      }, 2000);
    }
  }, [errorSubscription, newsLetterSubscriptionResponse]);
  const subscriptionFormWidget = (
    <>
      <div className="content mt-5 pt-3 subscription">
        {loadingSubscription ? (
          <Loader></Loader>
        ) : showMessage ? (
          <Message variant="success">{message}</Message>
        ) : (
          <></>
        )}
        {WidgetContent.map((item) => {
          const { WidgetTitle, WidgetDescription, InputVisibility } = item;
          return (
            <div key={WidgetTitle}>
              <h2 className="subscription-title">{WidgetTitle}</h2>
              <p className="par-subscription">{WidgetDescription}</p>
              {InputVisibility && (
                <>
                  <Formik
                    initialValues={{ email: "" }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                      dispatch(newsLetterSubscriptionAction(values.email));
                      setShowSubmitButton(false);
                      resetForm();
                    }}
                  >
                    {({ errors, touched, values }) => (
                      <Form>
                        <div className="input-group mobile-responive">
                          <Field
                            type="email"
                            name="email"
                            placeholder="Email:"
                            className={`form-control input-form-control input-height ${
                              touched.email && errors.email ? "is-invalid" : ""
                            }`}
                          />

                          <span className="input-group-btn">
                            <button
                              className="btn"
                              type="submit"
                              disabled={!values.email}
                            >
                              Subscribe
                            </button>
                          </span>
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback text-start"
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>

                  {/* <div className="input-group mobile-responive">
                  <input
                    type="email"
                    className="form-control input-form-control input-height"
                    placeholder="Email:"
                  />
                  <span className="input-group-btn">
                    <button className="btn" type="submit">
                      Subscribe
                    </button>
                  </span>
                </div> */}
                </>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
  return <div>{subscriptionFormWidget}</div>;
};

export default SubscriptionFormWidget;
