import React from "react";

const LargeSizeSvg = () => {
  return (
    <div>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="29" height="29" stroke="#C3C3C3" />
        <path
          d="M14.5328 20.088H18.5488V21H13.4128V9.816H14.5328V20.088Z"
          fill="#323232"
        />
      </svg>
    </div>
  );
};

export default LargeSizeSvg;
