import axios from "axios";
import {
  CART_ADD_ITEM,
  CART_CLEAR_ITEMS,
  CART_UPDATE_ITEMS_REQUEST,
  CART_UPDATE_ITEMS_SUCCESS,
  CART_UPDATE_ITEMS_FAIL,
  GET_CART_ITEMS_REQUEST,
  GET_CART_ITEMS_SUCCESS,
  GET_CART_ITEMS_FAIL,
  ADD_FAVOURITE_TO_REQUEST,
  ADD_FAVOURITE_TO_SUCCESS,
  ADD_FAVOURITE_TO_FAIL,
  LIST_OF_FAVOURITE_TO_REQUEST,
  LIST_OF_FAVOURITE_TO_SUCCESS,
  LIST_OF_FAVOURITE_TO_FAIL,
  REMOVE_FAVOURITE_TO_REQUEST,
  REMOVE_FAVOURITE_TO_SUCCESS,
  REMOVE_FAVOURITE_TO_FAIL,
} from "../constants/cartConstants";

export const addToCart = (id, qty) => async (dispatch, getState) => {
  const {
    userGuest: { userInfo },
    userLogin: { userLoginInfo },
  } = getState();

  let key1 = `addtocart_${id}.UpdatedShoppingCartItemId`;
  let key2 = `addtocart_${id}.EnteredQuantity`;
  // console.log(key1);
  // console.log(key2);
  let newItems = {
    [key1]: +id,
    [key2]: +qty,
  };

  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}ShoppingCart/AddProductToCartFromDetails/${id}?shoppingCartType=ShoppingCart`,
    newItems,
    {
      headers: {
        Authorization: `Bearer ${
          userLoginInfo ? userLoginInfo?.token : userInfo?.token
        }`,
      },
    }
  );

  dispatch({
    type: CART_ADD_ITEM,
    payload: data,
  });
};

export const updateCart =
  (updatedCartItems, id) => async (dispatch, getState) => {
    const {
      userGuest: { userInfo },
      userLogin: { userLoginInfo },
    } = getState();
    // console.log(userLoginInfo);
    try {
      dispatch({
        type: CART_UPDATE_ITEMS_REQUEST,
      });
      let newItems = {
        removefromcart: "",
      };
      updatedCartItems.forEach((item) => {
        newItems[`itemquantity${item.id}`] = item.quantity;
      });
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}ShoppingCart/UpdateCart`,
        newItems,
        {
          headers: {
            Authorization: `Bearer ${
              userLoginInfo ? userLoginInfo?.token : userInfo?.token
            }`,
          },
        }
      );
      dispatch({
        type: CART_UPDATE_ITEMS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({
        type: CART_UPDATE_ITEMS_FAIL,
        payload: message,
      });
    }
  };

export const clearCart = () => async (dispatch) => {
  dispatch({ type: CART_CLEAR_ITEMS });
  localStorage.removeItem("cartItems");
};

export const getCartItems = () => async (dispatch, getState) => {
  const {
    userGuest: { userInfo },
    userLogin: { userLoginInfo },
  } = getState();

  try {
    dispatch({
      type: GET_CART_ITEMS_REQUEST,
    });

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}ShoppingCart/Cart`,

      {
        headers: {
          Authorization: `Bearer ${
            userLoginInfo ? userLoginInfo?.token : userInfo?.token
          }`,
        },
      }
    );

    dispatch({
      type: GET_CART_ITEMS_SUCCESS,
      payload: data.items,
    });
  } catch (error) {
    dispatch({
      type: GET_CART_ITEMS_FAIL,
      payload: error.response.data,
    });
  }
};

export const addFavouriteToCart = (id) => async (dispatch, getState) => {
  const {
    userGuest: { userInfo },
    userLogin: { userLoginInfo },
  } = getState();
  // console.log(userInfo);
  try {
    dispatch({
      type: ADD_FAVOURITE_TO_REQUEST,
    });

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}ShoppingCart/AddProductToCartFromDetails/${id}?shoppingCartType=Wishlist`,
      {},
      {
        headers: {
          Authorization: `Bearer ${
            userLoginInfo ? userLoginInfo?.token : userInfo?.token
          }`,
        },
      }
    );

    dispatch({
      type: ADD_FAVOURITE_TO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_FAVOURITE_TO_FAIL,
      payload: error.response.data,
    });
  }
};
export const favouriteToCartlistAction =
  (gridId) => async (dispatch, getState) => {
    const {
      userGuest: { userInfo },
      userLogin: { userLoginInfo },
    } = getState();
    try {
      dispatch({
        type: LIST_OF_FAVOURITE_TO_REQUEST,
      });

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}Wishlist/Wishlist?customerGuid=${gridId}`,

        {
          headers: {
            Authorization: `Bearer ${
              userLoginInfo ? userLoginInfo?.token : userInfo?.token
            }`,
          },
        }
      );
      console.log(data);

      dispatch({
        type: LIST_OF_FAVOURITE_TO_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: LIST_OF_FAVOURITE_TO_FAIL,
        payload: error.response.data,
      });
    }
  };
export const removefavouriteToCartlistAction =
  (pId) => async (dispatch, getState) => {
    const {
      userGuest: { userInfo },
      userLogin: { userLoginInfo },
    } = getState();
    try {
      dispatch({
        type: REMOVE_FAVOURITE_TO_REQUEST,
      });
      const idInStringForm = String(pId);

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}Wishlist/UpdateWishlist`,
        { removefromcart: idInStringForm },
        {
          headers: {
            Authorization: `Bearer ${
              userLoginInfo ? userLoginInfo?.token : userInfo?.token
            }`,
          },
        }
      );

      dispatch({
        type: REMOVE_FAVOURITE_TO_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REMOVE_FAVOURITE_TO_FAIL,
        payload: error.response.data,
      });
    }
  };
