import React from "react";
import { Link } from "react-router-dom";
import cardImage from "../../CustomStyle/atcommerce-theme/images/card.webp";

const FlexiblePaymentWidget = ({ AdditionalProperties }) => {
  const { WidgetContent } = AdditionalProperties;
  const flexiblePaymentWidget = (
    <div className="text-center box">
      {WidgetContent.map((item, index) => {
        const { WidgetTitle, WidgetDescription, ParticularLink } = item;
        return (
          <div key={WidgetTitle}>
            <Link to={ParticularLink}>
              <img src={cardImage} alt="Image of Card" />
            </Link>
            <h2>{WidgetTitle}</h2>
            <p>{WidgetDescription}</p>
          </div>
        );
      })}
    </div>
  );
  return <div>{flexiblePaymentWidget}</div>;
};

export default FlexiblePaymentWidget;
