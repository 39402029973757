import React, { useState } from "react";

import { useEffect } from "react";
import { listProductCategories } from "../../actions/productActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { Link } from "react-router-dom";
import dressImage from "../../CustomStyle/atcommerce-theme/images/navbar-image.png";
import menuData from "../../DynamicData/lowerNavbarData.json";

import { useDispatch, useSelector } from "react-redux";

const LowerNavbarWidget = ({ AdditionalProperties }) => {
  const { WidgetTitle, WidgetContent } = AdditionalProperties;
  const dispatch = useDispatch();
  const productCategoryList = useSelector((state) => state.productCategoryList);
  const {
    loading: loadingCategories,
    error: errorCategories,
    categories,
  } = productCategoryList;
  console.log(categories);
  // console.log(categories?.categories_model_dto);
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    dispatch(listProductCategories());
  }, [dispatch]);

  const handleShowNavbar = () => {
    let element = document.getElementById("navbar-menus-container");
    if (Object.values(element?.style).includes("inline-block")) {
      element.style.display = "";
    } else {
      element.style.display = "inline-block";
    }
  };

  document.addEventListener("click", (e) => {
    let element = document.getElementById("navbar-menus-container");
    if (element?.style?.display == "inline-block") {
      if (element == e?.target || e?.target?.classList?.contains("dropdown")) {
        element.style.display = "inline-block";
      } else {
        element.style.display = "";
      }
    }
  });

  const lowerNavbarWidget = (
    <div className="container">
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse justify-content-center"
        id="navbarNav"
      >
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className="nav-link active" aria-current="page" to="/">
              {" "}
              {WidgetTitle}
            </Link>
          </li>
          {loadingCategories ? (
            <Loader></Loader>
          ) : errorCategories ? (
            <Message variant="danger">{errorCategories}</Message>
          ) : (
            categories.categories_model_dto
              .map((category) => (
                <li
                  key={category.name}
                  className="nav-item dropdown dropdown-hover position-static"
                  onClick={handleShowNavbar}
                >
                  <Link
                    to={`/category/${category.id}?name=${category.name}`}
                    className="nav-link dropdown-toggle"
                    onClick={handleClick}
                  >
                    {category.name}
                  </Link>
                  <div
                    className="dropdown-menu mt-0"
                    id="navbar-menus-container"
                    aria-labelledby="navbarDropdown"
                    style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                  >
                    <div className="row my-4">
                      <div className="col">
                        <div className="list-group list-group-flush sub-menu submenu-right">
                          {category?.sub_categories?.map((subItem) => {
                            // console.log(subItem);
                            return (
                              <div className="menu-subleft" key={subItem?.id}>
                                <Link
                                  to={`/category/${subItem?.id}?name=${subItem.name}`}
                                  onClick={handleClick}
                                >
                                  <strong className="mb-2">
                                    {subItem?.name}
                                  </strong>
                                </Link>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {menuData?.menuItems?.map((menuItem, index) => (
                        <div className="col" key={index}>
                          <div className="list-group list-group-flush sub-menu">
                            <strong className="mb-2">{menuItem.title}</strong>
                            {menuItem?.links?.map((link, index) => (
                              <a href={link.url} key={index}>
                                {link.text}
                              </a>
                            ))}
                          </div>
                        </div>
                      ))}
                      <div className="col">
                        <div className="list-group list-group-flush text-center">
                          <a>
                            <img
                              src={category?.picture_model?.image_url}
                              alt={category?.picture_model?.alternate_text}
                            />
                          </a>
                          <div className="navbar-name mt-1">Stitched</div>
                          <Link to="/" className="navbar-links text-center">
                            Shop Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))
              .slice(0, 7)
          )}
        </ul>
      </div>
    </div>
  );
  return <div>{lowerNavbarWidget}</div>;
};

export default LowerNavbarWidget;
