import React from "react";
import masterCardtImage from "../../CustomStyle/atcommerce-theme/images/m-card.png";
import visaCardtImage from "../../CustomStyle/atcommerce-theme/images/v-card.png";
import atmCardImage from "../../CustomStyle/atcommerce-theme/images/p-card.png";
import { Link } from "react-router-dom";

const FooterLogosWidget = ({ AdditionalProperties }) => {
  const { WidgetContent } = AdditionalProperties;

  const footerLogosWidget = (
    <div className="text-end f-card">
      {WidgetContent.filter((widget) => widget.Visibility).map((widget) => {
        const { WidgetTitle, ParticularLink } = widget;

        switch (WidgetTitle) {
          case "VisaCard":
            return (
              <Link to={ParticularLink} key={WidgetTitle} className="pe-1">
                <img src={masterCardtImage} alt="Image of VisaCard" />
              </Link>
            );
          case "MasterCard":
            return (
              <Link to={ParticularLink} key={WidgetTitle} className="px-1">
                <img src={visaCardtImage} alt="Image of MasterCard" />
              </Link>
            );
          case "ATMCard":
            return (
              <Link to={ParticularLink} key={WidgetTitle} className="px-1">
                <img src={atmCardImage} alt="Image of ATMCard" />
              </Link>
            );
          default:
            return null;
        }
      })}
    </div>
  );
  return <div>{footerLogosWidget}</div>;
};

export default FooterLogosWidget;
