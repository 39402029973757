import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_CREATE_RESET,
  PRODUCT_CREATE_FAIL,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_RESET,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_TOP_REQUEST,
  PRODUCT_TOP_SUCCESS,
  PRODUCT_TOP_FAIL,
  PRODUCT_CATEGORY_REQUEST,
  PRODUCT_CATEGORY_SUCCESS,
  PRODUCT_CATEGORY_FAIL,
  GET_ALL_FEATURED_PRODUCT_REQUEST,
  GET_ALL_FEATURED_PRODUCT_SUCCESS,
  GET_ALL_FEATURED_PRODUCTY_FAIL,
  BEST_SELLER_REQUEST,
  BEST_SELLER_SUCCESS,
  BEST_SELLER_FAIL,
  BEST_SLIDER_CONTENT_REQUEST,
  BEST_SLIDER_CONTENT_SUCCESS,
  BEST_SLIDER_CONTENT_FAIL,
  SHOP_BY_CATEGORY_DETAIL_REQUEST,
  SHOP_BY_CATEGORY_DETAIL_SUCCESS,
  SHOP_BY_CATEGORY_DETAIL_FAIL,
  SUB_CATEGORY_DETAIL_REQUEST,
  SUB_CATEGORY_DETAIL_SUCCESS,
  SUB_CATEGORY_DETAIL_FAIL,
  PRODUCT_SEARCH_REQUEST,
  PRODUCT_SEARCH_SUCCESS,
  PRODUCT_SEARCH_FAIL,
  APPLY_COUPON_REQUEST,
  APPLY_COUPON_SUCCESS,
  APPLY_COUPON_FAIL,
} from "../constants/productConstants";

export const productListReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, products: [] };
    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productDetailsReducer = (
  state = { product: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: action.payload };
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_REQUEST:
      return { loading: true };
    case PRODUCT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REQUEST:
      return { loading: true };
    case PRODUCT_CREATE_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case PRODUCT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const productUpdateReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return { loading: true };
    case PRODUCT_UPDATE_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case PRODUCT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_UPDATE_RESET:
      return { product: {} };
    default:
      return state;
  }
};

export const productReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REVIEW_REQUEST:
      return { loading: true };
    case PRODUCT_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_CREATE_REVIEW_RESET:
      return {};
    default:
      return state;
  }
};

export const productTopRatedReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_TOP_REQUEST:
      return { loading: true, products: [] };
    case PRODUCT_TOP_SUCCESS:
      return { loading: false, products: action.payload };
    case PRODUCT_TOP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productCategoryListReducer = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_CATEGORY_REQUEST:
      return { loading: true };
    case PRODUCT_CATEGORY_SUCCESS:
      return { loading: false, categories: action.payload };
    case PRODUCT_CATEGORY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const productCategoryDetailReducer = (
  state = { loading: true, categoryDetail: {} },
  action
) => {
  switch (action.type) {
    case SHOP_BY_CATEGORY_DETAIL_REQUEST:
      return { loading: true };
    case SHOP_BY_CATEGORY_DETAIL_SUCCESS:
      return { loading: false, categoryDetail: action.payload };
    case SHOP_BY_CATEGORY_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productSubCategoryiesReducer = (
  state = { loading: true, subCategoryList: [] },
  action
) => {
  switch (action.type) {
    case SUB_CATEGORY_DETAIL_REQUEST:
      return { loading: true };
    case SUB_CATEGORY_DETAIL_SUCCESS:
      return { loading: false, subCategoryList: action.payload };
    case SUB_CATEGORY_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const listOfFeaturedProductsReducer = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case GET_ALL_FEATURED_PRODUCT_REQUEST:
      return { loading: true };
    case GET_ALL_FEATURED_PRODUCT_SUCCESS:
      return { loading: false, featureProducts: action.payload };
    case GET_ALL_FEATURED_PRODUCTY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const bestSellerReducer = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case BEST_SELLER_REQUEST:
      return { loading: true };
    case BEST_SELLER_SUCCESS:
      return { loading: false, allBestSeller: action.payload };
    case BEST_SELLER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const SliderContentReducer = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case BEST_SLIDER_CONTENT_REQUEST:
      return { loading: true };
    case BEST_SLIDER_CONTENT_SUCCESS:
      return { loading: false, sliderContent: action.payload };
    case BEST_SLIDER_CONTENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productSearchReducer = (
  state = { loading: true, searchProducts: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_SEARCH_REQUEST:
      return { loading: true };
    case PRODUCT_SEARCH_SUCCESS:
      return { loading: false, searchProducts: action.payload };
    case PRODUCT_SEARCH_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const applyCouponReducer = (
  state = { loading: true, applyCouponResponse: [] },
  action
) => {
  switch (action.type) {
    case APPLY_COUPON_REQUEST:
      return { loading: true };
    case APPLY_COUPON_SUCCESS:
      return { loading: false, applyCouponResponse: action.payload };
    case APPLY_COUPON_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
